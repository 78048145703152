export class HTMLElementFactory
{
	public static createHTMLLinkElement(href: string, type: string, rel: string) : HTMLLinkElement
	{
		const elem = document.createElement("LINK") as HTMLLinkElement;
		elem.href = href;
		elem.type = type;
		elem.rel = rel;
		return elem;
	}

	public static createHTMLStyleElement(id: string) : HTMLStyleElement
	{
		const elem = document.createElement("STYLE") as HTMLStyleElement;
		elem.id = id;
		return elem;
	}

	public static createHTMLInputElement(type: string, style?: Partial<CSSStyleDeclaration>, id?: string, autocomplete?: string, attributes?: Object, onchange?: () => void, onblur?: () => void, onfocus?: () => void, oninput?: (event: InputEvent) => void) : HTMLInputElement
	{
		const elem = document.createElement("INPUT") as HTMLInputElement;
		elem.type = type;
		if (id)
		{
			elem.id = id;
		}

		if (style)
		{
			elem.style.position = style.position;
			elem.style.zIndex = style.zIndex;
			elem.style.width = style.width;
		}

		if (autocomplete)
		{
			elem.autocomplete = autocomplete;
		}

		if (attributes)
		{
			Object.keys(attributes).forEach(key => 
			{
				elem.setAttribute(key, attributes[key]);
			});
		}

		if (onchange)
		{
			elem.onchange = onchange;
		}

		if (onblur)
		{
			elem.onblur = onblur;
		}

		if (onfocus)
		{
			elem.onfocus = onfocus;
		}

		if (oninput)
		{
			elem.oninput = oninput;
		}
		return elem;
	}

	public static createHTMLTextareaElement(style?: Partial<CSSStyleDeclaration>, id?: string, attributes?: Object, onchange?: () => void, onblur?: () => void, onfocus?: () => void, oninput?: (event: InputEvent) => void) : HTMLTextAreaElement
	{
		const elem = document.createElement("TEXTAREA") as HTMLTextAreaElement;
		if (id)
		{
			elem.id = id;
		}

		if (style)
		{
			elem.style.position = style.position;
			elem.style.zIndex = style.zIndex;
			elem.style.width = style.width;
		}

		if (attributes)
		{
			Object.keys(attributes).forEach(key => 
			{
				elem.setAttribute(key, attributes[key]);
			});
		}

		if (onchange)
		{
			elem.onchange = onchange;
		}

		if (onblur)
		{
			elem.onblur = onblur;
		}

		if (onfocus)
		{
			elem.onfocus = onfocus;
		}

		if (oninput)
		{
			elem.oninput = oninput;
		}
		return elem;
	}

	public static createHTMLDivElement(id: string, attributes?: Object, style?:Partial<CSSStyleDeclaration>, innerHTML?: string, onclick?: (event: Event) => void, onmouseenter?: () => void, onmouseleave?: () => void, text?: string, labelText?: string) : HTMLElement
	{
		const elem = document.createElement("DIV") as HTMLElement;
		elem.id = id;
		if (style)
		{
			elem.style.display = style.display;
			elem.style.zIndex = style.position;
			elem.style.top = style.top;
			elem.style.left = style.left;
			elem.style.position = style.position;
			elem.style.backgroundColor = style.backgroundColor;
			elem.style.backgroundImage = style.backgroundImage;
		}
		if (attributes)
		{
			Object.keys(attributes).forEach(key => 
			{
				elem.setAttribute(key, attributes[key]);
			});
		}
		if (innerHTML)
		{
			elem.innerHTML = innerHTML;
		}
		if (onclick)
		{
			elem.onclick = onclick;
		}
		if (onmouseenter)
		{
			elem.onmouseenter = onmouseenter;
		}
		if (onmouseleave)
		{
			elem.onmouseleave = onmouseleave;
		}
		if (labelText)
		{
			const labelElem = document.createElement("SPAN");
			labelElem.classList.add("div-label");
			labelElem.id = `div-label--${id}`;
			labelElem.appendChild(document.createTextNode(labelText));
			elem.appendChild(labelElem);
		}
		if (text)
		{
			elem.appendChild(document.createTextNode(text));
		}
		return elem;
	}

	public static createHTMLSpanElement(id: string, title?: string, innerHTML?: string, attributes?: Object, onclick?: (event: Event) => void, text?: string) : HTMLElement
	{
		const elem = document.createElement("SPAN") as HTMLElement;
		elem.id = id;
		if (title)
		{
			elem.title = title;
		}
		if (innerHTML)
		{
			elem.innerHTML = innerHTML;
		}
		if (attributes)
		{
			Object.keys(attributes).forEach(key => 
			{
				elem.setAttribute(key, attributes[key]);
			});
		}
		if (onclick)
		{
			elem.onclick = onclick;
		}
		if (text)
		{
			elem.appendChild(document.createTextNode(text));
		}
		return elem;
	}

	public static createHTMLImgElement(id: string, src: string, attributes?: Object, title?: string, onclick?: () => void, onmouseenter?: () => void, onmouseleave?: () => void) : HTMLImageElement
	{
		const elem = document.createElement("IMG") as HTMLImageElement;
		elem.id = id;
		elem.src = src;
		if (attributes)
		{
			Object.keys(attributes).forEach(key => 
			{
				elem.setAttribute(key, attributes[key]);
			});
		}
		if (title)
		{
			elem.title = title;
		}
		if (onclick)
		{
			elem.onclick = onclick;
		}
		if (onmouseenter)
		{
			elem.onmouseenter = onmouseenter;
		}
		if (onmouseleave)
		{
			elem.onmouseleave = onmouseleave;
		}
		return elem;
	}

	public static createHTMLImgElementInAnchor(id: string, src: string, target: string, href: string, attributes?: Object, title?: string, onclick?: () => void, onmouseenter?: () => void, onmouseleave?: () => void) : HTMLAnchorElement
	{
		const anchorElement = this.createHTMLAnchorElement(`${id}_anchor`, target, href);
		anchorElement.appendChild(this.createHTMLImgElement(id, src, attributes, title, onclick, onmouseenter, onmouseleave));

		return anchorElement;
	}

	public static createHTMLSelectElement(id: string, style?:Partial<CSSStyleDeclaration>, attributes?: Object, onchange?: (event: Event) => void) : HTMLSelectElement
	{
		const elem = document.createElement("SELECT") as HTMLSelectElement;
		elem.id = id;
		if (style)
		{
			elem.style.display = style.display;
		}
		if (attributes)
		{
			Object.keys(attributes).forEach(key => 
			{
				elem.setAttribute(key, attributes[key]);
			});
		}
		if (onchange)
		{
			elem.onchange = onchange;
		}
		return elem;
	}

	public static createHTMLOptionElement(value: string) : HTMLOptionElement
	{
		const elem = document.createElement("OPTION") as HTMLOptionElement;
		elem.value = value;
		elem.appendChild(document.createTextNode(value));
		return elem;
	}

	public static createHTMLULElement(id: string, style?:Partial<CSSStyleDeclaration>) : HTMLUListElement
	{
		const elem = document.createElement("UL") as HTMLUListElement;
		elem.id = id;
		elem.style.display = style.display;
		return elem;
	}

	public static createHTMLIFrameElement(src: string, width: string, height: string, frameBorder: string, style:Partial<CSSStyleDeclaration>) : HTMLIFrameElement
	{
		const elem = document.createElement("IFRAME") as HTMLIFrameElement;
		elem.src = src;
		elem.width = width;
		elem.height = height;
		elem.frameBorder = frameBorder;
		elem.style.border = style.border;
		elem.allowFullscreen = true;
		return elem;
	}

	public static createHTMLHeadingElement(level: Number, id: string, text: string, attributes?: Object) : HTMLHeadingElement
	{
		const elem = document.createElement("H" + level) as HTMLHeadingElement;
		elem.id = id;
		elem.appendChild(document.createTextNode(text));
		if (attributes)
		{
			Object.keys(attributes).forEach(key => 
			{
				elem.setAttribute(key, attributes[key]);
			});
		}
		return elem;
	}

	public static createHTMLAnchorElement(id: string, target: string, href: string) : HTMLAnchorElement
	{
		const elem = document.createElement("A") as HTMLAnchorElement;
		elem.id = id;
		elem.target = target;
		elem.href = href;
		return elem;
	}

	public static createHTMLListElement() : HTMLLIElement
	{
		const elem = document.createElement("LI") as HTMLLIElement;
		return elem;
	}

	public static createHTMLHeaderElement(id: string, portfolioTrigger: Function, mapTrigger: Function, logoUrl?: string, customTabName?: string, customTabLink?: string, customText?: string, hideAllTabs?: boolean): HTMLDivElement
	{
		const elem = document.createElement("DIV") as HTMLDivElement;
		elem.id = id;

		/** logo */
		const logoElem = this.createHTMLImgElement("template_icon", logoUrl);

		/** list tab */
		const listTabElem = this.createHTMLSpanElement("template_list_tab", null, null, {class: "template_tab"});
		listTabElem.appendChild(document.createTextNode("LISTE"));
		listTabElem.onclick = () => portfolioTrigger();

		/** map tab */
		const mapTabElem = this.createHTMLSpanElement("template_map_tab", null, null, {class: "template_tab"});
		mapTabElem.appendChild(document.createTextNode("CARTOGRAPHIE"));
		mapTabElem.onclick = () => mapTrigger();

		/** custom tab */
		const customTabElem = this.createHTMLSpanElement("template_custom_tab", null, null, {class: "template_tab"});
		customTabElem.appendChild(document.createTextNode(customTabName));
		customTabElem.onclick = () => window.open(customTabLink);

		/** custom text */
		const customTextElem = this.createHTMLSpanElement("template_custom_text");
		customTextElem.appendChild(document.createTextNode(customText));

		/** tabs container */
		const tabsContainer = this.createHTMLDivElement("template_tabs_container");
		
		/** drop button */
		const dropButtonElem = this.createHTMLSpanElement("header_drop_button");
		dropButtonElem.onclick = () => 
		{
			dropButtonElem.classList.toggle("clicked");
			elem.classList.toggle("open");
		};
		dropButtonElem.appendChild(HTMLElementFactory.createHTMLSpanElement("drop_burger_button", undefined, undefined, {class: "hamburger-toggle"}));

		/** logo & drop trigger container */
		const logoDropButtonContainer = this.createHTMLDivElement("template_logo_drop_container");
		logoUrl && logoDropButtonContainer.appendChild(logoElem);

		/** logo tabs container */
		const logoTabsContainer = this.createHTMLDivElement("template_logo_tabs_container");
		logoTabsContainer.appendChild(logoDropButtonContainer);
		logoTabsContainer.appendChild(tabsContainer);
		
		if (!hideAllTabs)
		{
			tabsContainer.appendChild(listTabElem);
			tabsContainer.appendChild(mapTabElem);
			customTabName && tabsContainer.appendChild(customTabElem);
			logoDropButtonContainer.appendChild(dropButtonElem);
		}

		elem.appendChild(logoTabsContainer);
		customText && elem.appendChild(customTextElem);

		return elem;
	}

	public static createHTMLLoaderElement() : HTMLElement
	{
		return HTMLElementFactory.createHTMLDivElement(
			"loader",
			{ class: "loader-wrapper" },
			undefined,
			`
			<div class="loader-points">
				<div class="loader-point">
			  		<div class="point point-1"></div>
				  	<div class="point-shadow point-shadow-1"></div>
				</div>
				<div class="loader-point">
					<div class="point point-2"></div>
					<div class="point-shadow point-shadow-2"></div>
				</div>
				<div class="loader-point">
					<div class="point point-3"></div>
					<div class="point-shadow point-shadow-3"></div>
				</div>
			</div>
			<p class="loader-text"></p>`
		);
	}

}