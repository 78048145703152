import { Plateform } from "core";
import { WebView360GlobalSettings } from "globalSettings";
import { HTMLElementFactory } from "./helpers/htmlElementFactory";

export type DrawerState = "opened" | "closed" | "extended";
export type DrawerSide = "left" | "right";

export interface IDrawer
{
	init(topElement?: HTMLElement, customText?: string, ) : void
	render();
	open();
	openExtended(): void
	close();
	setOnMoveListener(onDrawerOpen: () => void, onDrawerClose: () => void, onDrawerExtended: () => void);
	getState() : DrawerState;
	getScrollableElement(): HTMLElement;
	getScrollableContainerElement(): HTMLElement;
	getDrawerElement(): HTMLElement;
}

export class Drawer implements IDrawer
{
	protected _state: DrawerState;
	protected _id: string;
	protected _side: DrawerSide;
	protected _defaultView: Plateform;
	protected _drawerToggleElement: HTMLElement;
	protected _drawerButtonsContainerElement: HTMLElement;
	protected _extendToggleElement: HTMLElement;
	protected _extendButtonCondition: boolean;
	protected _hideButtons: boolean;
	protected _drawerElement : HTMLElement;
	protected _drawerTopContentElement : HTMLElement;
	protected _drawerContentScrollableContainerElement : HTMLElement;
	protected _drawerContentScrollableElement : HTMLElement

	private _onDrawerOpen : (fromState) => void
	private _onDrawerExtended : (fromState) => void
	private _onDrawerClose : (fromState) => void
	private _drawerContentElement: HTMLElement;

	public constructor(state: DrawerState = "opened", side: DrawerSide, id: string, extendButtonCondition: boolean = false, hideButtons: boolean = false)
	{
		this._state = state;
		this._side = side;
		this._id = id;
		this._extendButtonCondition = extendButtonCondition;
		this._hideButtons = hideButtons;
		this._initDom();
	}

	public init(topElement?: HTMLElement, customText?: string) : void
	{
		WebView360GlobalSettings.debug && console.debug("[Drawer] init");
		
		customText && this._drawerTopContentElement.appendChild(HTMLElementFactory.createHTMLSpanElement("template_custom_text", null, null, null, null, customText));
		
		if (topElement)
		{
			this._drawerTopContentElement.appendChild(topElement);
		}
	}
	
	private _initDom(): void
	{
		WebView360GlobalSettings.debug && console.debug("[Drawer] _initDom");

		this._drawerElement = HTMLElementFactory.createHTMLDivElement("drawer_" + this._id, {class: "drawer " + (this._side == "right" ? "right" : "")});
		this._drawerContentElement = HTMLElementFactory.createHTMLDivElement("drawer_content");
		this._drawerTopContentElement = HTMLElementFactory.createHTMLDivElement("drawer_top");
		this._drawerContentScrollableContainerElement = HTMLElementFactory.createHTMLDivElement("scroll_container");
		this._drawerContentScrollableElement = HTMLElementFactory.createHTMLDivElement("scroll_element");


		this._drawerButtonsContainerElement = HTMLElementFactory.createHTMLSpanElement("drawer_buttons_container");

		this._drawerToggleElement = HTMLElementFactory.createHTMLSpanElement(
			"toggle_button",
			"Masquer",
			`
			<svg id="arrow_button" fill="#000000" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
			<path d="M15.41 16.09l-4.58-4.59 4.58-4.59L14 5.5l-6 6 6 6z"/>
			<path d="M0-.5h24v24H0z" fill="none"/>
			</svg>`,
			undefined, 
			() =>
			{
				if (this._state == "opened")
				{
					this.close();
				}
				else
				{
					this.open();
				}
			}
		);

		this._extendToggleElement = HTMLElementFactory.createHTMLSpanElement(
			"extend_toggle_button",
			"Masquer",
			`
			<svg id="arrow_button" fill="#000000" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
			<path d="M15.41 16.09l-4.58-4.59 4.58-4.59L14 5.5l-6 6 6 6z"/>
			<path d="M0-.5h24v24H0z" fill="none"/>
			</svg>`,
			undefined, 
			() =>
			{
				if (this._state == "extended")
				{
					this.open();
				}
				else
				{
					this.openExtended();
				}
			}
		);
	}
		
	public render() : HTMLElement
	{
		WebView360GlobalSettings.debug && console.debug("[Drawer] render");

		if (this._state == "opened")
		{
			this.open();
		}
		else if (this._state == "extended")
		{
			this.openExtended();
		}
		else
		{
			this.close();
		}

		this._drawerContentScrollableContainerElement.appendChild(this._drawerContentScrollableElement);
		this._drawerButtonsContainerElement.appendChild(this._drawerToggleElement);
		this._extendButtonCondition && this._drawerButtonsContainerElement.appendChild(this._extendToggleElement);
		!this._hideButtons && this._drawerElement.appendChild(this._drawerButtonsContainerElement);
		this._drawerContentElement.appendChild(this._drawerTopContentElement);
		this._drawerContentElement.appendChild(this._drawerContentScrollableContainerElement);
		this._drawerElement.appendChild(this._drawerContentElement);
		return (this._drawerElement);
	}

	public setOnMoveListener(onDrawerOpen: (fromState) => void, onDrawerClose: (fromState) => void, onDrawerExtended: (fromState) => void) : void
	{
		this._onDrawerClose = onDrawerClose;
		this._onDrawerOpen = onDrawerOpen;
		this._onDrawerExtended = onDrawerExtended;
	}

	public getState() : DrawerState 
	{
		return this._state;
	}
	
	public getScrollableElement(): HTMLElement 
	{
		return this._drawerContentScrollableElement;
	}

	public getScrollableContainerElement(): HTMLElement
	{
		return this._drawerContentScrollableContainerElement;
	}

	public open() : void
	{
		WebView360GlobalSettings.debug && console.debug("[Drawer] open");

		this._drawerElement.classList.remove("closed");
		this._drawerElement.classList.remove("extended");
		this._drawerToggleElement.title = "Masquer";
		this._drawerToggleElement.classList.remove("closed");
		this._drawerToggleElement.classList.remove("hidden");
		this._extendToggleElement.title = "Etendre";
		this._extendToggleElement.classList.remove("hidden");
		this._extendToggleElement.classList.add("closed");
		this._onDrawerOpen && this._onDrawerOpen(this._state);
		this._state = "opened";
		setTimeout(() => 
		{
			this._drawerTopContentElement.classList.remove("fixed");
		}, 1000);
	}
	
	public close() : void
	{
		WebView360GlobalSettings.debug && console.debug("[Drawer] close");

		this._drawerTopContentElement.classList.add("fixed");
		this._drawerElement.classList.add("closed");
		this._drawerElement.classList.remove("extended");
		this._drawerToggleElement.title = "Afficher";
		this._drawerToggleElement.classList.add("closed");
		this._extendToggleElement.title = "Etendre";
		this._extendToggleElement.classList.add("hidden");
		this._onDrawerClose && this._onDrawerClose(this._state);
		this._state = "closed";
	}

	public openExtended(): void
	{
		WebView360GlobalSettings.debug && console.debug("[Drawer] openExtended");

		this._drawerElement.classList.remove("closed");
		this._drawerElement.classList.add("extended");
		this._drawerToggleElement.classList.add("hidden");
		this._drawerToggleElement.classList.remove("closed");
		this._extendToggleElement.title = "Afficher la carte";
		this._extendToggleElement.classList.remove("closed");
		this._extendToggleElement.classList.remove("hidden");
		this._onDrawerExtended && this._onDrawerExtended(this._state);
		this._state = "extended";

		setTimeout(() => 
		{
			this._drawerTopContentElement.classList.remove("fixed");
		}, 1000);
	}

	public getDrawerElement(): HTMLElement
	{
		return this._drawerElement;
	}
}