import { IAPIController } from "apiController";
import { IWebView360Config } from "core";
import { WebView360GlobalSettings } from "globalSettings";
import { HTMLElementFactory } from "helpers/htmlElementFactory";
import { IVisitsDrawer } from "visitsDrawer";

export interface IConfigTemplateController
{
	fillConfigFromTemplate(config: IWebView360Config, templateName: string): Promise<Object>
	getHTMLTemplateHeader(config: IWebView360Config, drawer: IVisitsDrawer): HTMLDivElement
}

export class ConfigTemplateController implements IConfigTemplateController
{
	private _apiController: IAPIController;

	public constructor(apiController: IAPIController)
	{
		this._apiController = apiController;
	}

	public async fillConfigFromTemplate(config: IWebView360Config, templateName: string): Promise<Object>
	{
		return this._apiController.getTemplate(templateName)
			.then((template: IWebView360Config) => 
			{
				template.limit = template["limite"];
				template.group = template["groupe"];
				delete template["limite"];
				delete template["groupe"];
				config = {...config, ...template};
				WebView360GlobalSettings.debug && console.debug(`[ConfigTemplateController] getTemplate("${templateName}") resolved`);
				return Promise.resolve(config);
			});
	}

	public getHTMLTemplateHeader(config: IWebView360Config, drawer: IVisitsDrawer): HTMLDivElement
	{
		let elem;
		if (config.name)
		{
			elem = HTMLElementFactory.createHTMLHeaderElement("template_header", drawer.openExtended.bind(drawer), drawer.open.bind(drawer), config.logoURL, config.customTabName, config.customTabLink, config.customText);
		}
		else if (config.id)
		{
			elem = HTMLElementFactory.createHTMLHeaderElement("template_header", drawer.openExtended.bind(drawer), drawer.open.bind(drawer), `${WebView360GlobalSettings.logoPath}logo1001visites.png`, undefined, undefined, undefined, true);
		}
		else
		{
			elem = HTMLElementFactory.createHTMLHeaderElement("template_header", drawer.openExtended.bind(drawer), drawer.open.bind(drawer), `${WebView360GlobalSettings.logoPath}logo1001visites.png`, "APPARAITRE PARMIS CES ENTREPRISES", "https://www.web-visite.com/");
		}

		drawer.setTemplateHeaderButtons(elem.querySelector("#template_list_tab"), elem.querySelector("#template_map_tab"), config.plateform);

		return elem;
	}
}

