import { IWebView360Config } from "core";
import { SearchCriteria } from "searchEngine";

export class SearchHelper
{
	public static configToSearchCriteria(config: IWebView360Config): SearchCriteria[]
	{
		const searchCriterias: SearchCriteria[] = [];

		if (config.category)
		{
			searchCriterias.push({field: "Categorie", value: config.category});
		}

		if (config.activity)
		{
			searchCriterias.push({field: "Sous_cat", value: config.activity});
		}

		if (config.department)
		{
			searchCriterias.push({field: "Departement", value: config.department});
		}

		if (config.city)
		{
			searchCriterias.push({field: "Ville", value: config.city});
		}

		if (config.group)
		{
			searchCriterias.push({field: "Groupe", value: config.group});
		}

		if (config.attribute)
		{
			const criteria = this.attributesToSearchCriteria(config.attribute);
			criteria && searchCriterias.push(criteria);
		}

		if (config.id)
		{
			searchCriterias.push({field: "Id", value: config.id});
		}

		return searchCriterias;
	}

	public static attributesToSearchCriteria(attribute: string): SearchCriteria
	{
		switch (attribute) 
		{
		case "Coups de coeur":
			return {value: 1, field: "Favoris"};
		case "Versions enrichies":
			return {value: "#NOTNULL#", field: "Lien_VE"};
		case "Vues aériennes":
			return {value: 1, field: "Drone"};
		}
	}

	public static searchCriteriaToAttribute(searchCriteria: SearchCriteria): string
	{
		switch (searchCriteria.field) 
		{
		case "Favoris":
			return searchCriteria.value == 1 ? "Coups de coeur" : null;
		case "Lien_VE":
			return searchCriteria.value == "#NOTNULL#" ? "Versions enrichies" : null;
		case "Drone":
			return searchCriteria.value == 1 ? "Vues aériennes" : null;
		}
	}
}