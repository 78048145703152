import { IAPIController } from "apiController";
import { VisitArray } from "dataController";
import { WebView360GlobalSettings } from "globalSettings";
import { DomHelper } from "helpers/domHelper";
import { HTMLElementFactory } from "helpers/htmlElementFactory";
import { SearchHelper } from "helpers/searchHelper";
import { ISearchEngine, SearchCriteria } from "searchEngine";

export interface ISearchFactory
{
	searchEngine: ISearchEngine;
	init(apiController: IAPIController, criterias?: SearchCriteria[]): Promise<void> 
	initDom():void 
	render(): HTMLElement
	dismissAutoCompletion(): void
	getSearchTextElement(): HTMLInputElement
	getCategorySelect(): ISearchSelect
	getSubCategorySelect(): ISearchSelect
	getDepartmentSelect(): ISearchSelect
	getCitySelect(): ISearchSelect
	getGroupSelect(): ISearchSelect
	getTypeSelect(): ISearchSelect
	getDynamicAutoCompleteElement(): HTMLUListElement
	searchByText(value?: string) : void
	searchByValueField(criterias: SearchCriteria[], valueToSetInput?: string): void 
	searchByValueFieldStatic(data: VisitArray, criterias: SearchCriteria[]): VisitArray
	notifyFilter(enabled: boolean): void;
	resetSelectsDiplay(): void
}

export interface ISearchSelect
{
	htmlElement: HTMLSelectElement;
	placeholder: string;
	field: string;
	options: string[];
	setValue(value?: string, subValue?: string, fromConfig?: boolean): Promise<void>;
	setValues(values?: string[], fromConfig?: boolean): void;
}

export class SearchFactory implements ISearchFactory
{
	public searchEngine: ISearchEngine;
	
	private _apiController: IAPIController;

	private _name: string;

	private _debugAutoCompletion: boolean = false;

	private _searchTextInputElement: HTMLInputElement;
	private _categorySelect: ISearchSelect;
	private _subCategorySelect: ISearchSelect;
	private _departmentSelect: ISearchSelect;
	private _citySelect: ISearchSelect;
	private _groupSelect: ISearchSelect;
	private _typeSelect: ISearchSelect;
	private _dynamicAutoCompleteElement: HTMLUListElement;

	private _searchBoxElement: HTMLElement;
	private _searchInputContainerElement: HTMLElement;
	private _searchSelectsContainerElement: HTMLElement;
	private _cancelSearchElement: HTMLImageElement;
	private _geoFieldsContainer: HTMLElement;
	private _activityFieldsContainer: HTMLElement;
	private _advancedSearchToggleElement: HTMLElement;

	public constructor(searchEngine: ISearchEngine, name: string)
	{
		this.searchEngine = searchEngine;
		this._name = name;
		
		this._categorySelect = {} as ISearchSelect;
		this._subCategorySelect = {} as ISearchSelect;
		this._departmentSelect = {} as ISearchSelect;
		this._citySelect = {} as ISearchSelect;
		this._groupSelect = {} as ISearchSelect;
		this._typeSelect = {} as ISearchSelect;
	}
	
	public initDom():void 
	{
		this._searchTextInputElement = HTMLElementFactory.createHTMLInputElement(
			"text",
			undefined,
			`pac-input-${this._name}`,
			"off",
			{ 
				list: "dynamic_autocomplete",
				class: "search-input" 
			},
			undefined,
			() => // onblur
			{
				this.dismissAutoCompletion();
				
			},
			() => // onfocus
			{
				this._dynamicAutoCompleteElement.style.display = "block";
			},
			(event) => // oninput
			{
				this.resetSelectsDiplay();
				this.searchByText((event.target as HTMLInputElement).value);
			}
		);

		/** Categorie */
		this._categorySelect.setValue = (value?: string, subValue?: string, fromConfig?: boolean) =>
		{
			return this._setSelectValue(this._categorySelect, value, [this._citySelect, this._typeSelect], [this._subCategorySelect], [this._departmentSelect, this._subCategorySelect, this._typeSelect], fromConfig)
				.then(() => 
				{
					if (subValue && this._subCategorySelect.options.indexOf(subValue))
					{
						return this._subCategorySelect.setValue(subValue, undefined, fromConfig);
					}
				});
		};
		this._categorySelect.htmlElement = HTMLElementFactory.createHTMLSelectElement("category", undefined, {class: "search_select search_field"}, () =>
		{
			this._searchFromSelect(this._categorySelect);
		});
		this._categorySelect.placeholder = "Toutes les catégories";
		this._categorySelect.field = "Categorie";

		/** Sous_Categorie */
		this._subCategorySelect.setValue = (value?: string, _subValue?: string, fromConfig?: boolean) =>
		{
			return this._setSelectValue(this._subCategorySelect, value, [this._citySelect], undefined, [this._departmentSelect, this._typeSelect], fromConfig);
		};
		this._subCategorySelect.htmlElement = HTMLElementFactory.createHTMLSelectElement("sub_category", undefined, {class: "search_select search_field sub_field hide"}, () =>
		{
			this._searchFromSelect(this._categorySelect, this._subCategorySelect);
		});
		this._subCategorySelect.placeholder = "Toutes les activités";
		this._subCategorySelect.field = "Sous_cat";

		/** Departement */
		this._departmentSelect.setValue = (value?: string, subValue?: string, fromConfig?: boolean) =>
		{
			return this._setSelectValue(this._departmentSelect, value, [this._subCategorySelect], [this._citySelect], [this._categorySelect, this._citySelect, this._typeSelect], fromConfig)
				.then(() => 
				{
					if (subValue && this._citySelect.options.indexOf(subValue))
					{
						return this._citySelect.setValue(subValue, undefined, fromConfig);
					}
				});
		};

		this._departmentSelect.setValues = (values?: string[], fromConfig?: boolean) =>
		{
			this._setSelectValue(this._departmentSelect, null, [this._subCategorySelect, this._citySelect], [], [this._categorySelect, this._citySelect, this._typeSelect], fromConfig)
				.then(() => 
				{
					this._departmentSelect.htmlElement.childNodes.forEach((node: HTMLOptionElement) => 
					{
						if (values.indexOf(node.innerText) >= 0)
						{
							node.selected = true;
						}
						else
						{
							node.selected = false;
						}
					});
				});
		};

		this._departmentSelect.htmlElement = HTMLElementFactory.createHTMLSelectElement("department", undefined, {class: "search_select search_field"}, () =>
		{
			this._searchFromSelect(this._departmentSelect);
		});
		this._departmentSelect.placeholder = "Tous les départements";
		this._departmentSelect.field = "Departement";

		/** Ville */
		this._citySelect.setValue = (value?: string, _subValue?: string, fromConfig?: boolean) =>
		{
			return this._setSelectValue(this._citySelect, value, [this._subCategorySelect], undefined, [this._categorySelect, this._typeSelect], fromConfig);
		};
		this._citySelect.htmlElement = HTMLElementFactory.createHTMLSelectElement("city", undefined, {class: "search_select search_field sub_field hide"}, () =>
		{
			return this._searchFromSelect(this._departmentSelect, this._citySelect);
		});
		this._citySelect.placeholder = "Toutes les villes";
		this._citySelect.field = "Ville";

		/** Groupe */
		this._groupSelect.setValue = (value?: string, _subValue?: string, fromConfig?: boolean) =>
		{
			return this._setSelectValue(this._groupSelect, value, [this._subCategorySelect, this._citySelect], undefined, undefined, fromConfig);
		};
		this._groupSelect.htmlElement = HTMLElementFactory.createHTMLSelectElement("group", undefined, {class: "search_select search_field"}, () =>
		{
			this._searchFromSelect(this._groupSelect);
			this._groupSelect.setValue();
		});
		this._groupSelect.field = "Groupe";

		/** Type */
		this._typeSelect.setValue = (value?: string, _subValue?: string, fromConfig?: boolean) =>
		{
			return this._setSelectValue(this._typeSelect, value, [this._subCategorySelect, this._citySelect], undefined, [this._categorySelect, this._departmentSelect, this._citySelect, this._subCategorySelect], fromConfig);
		};
		this._typeSelect.htmlElement = HTMLElementFactory.createHTMLSelectElement("visit_type", undefined, {class: "search_select search_field"}, () =>
		{
			this._searchByAttribute();
			this._typeSelect.setValue();
		});
		this._typeSelect.placeholder = "Toutes les visites";

		this._dynamicAutoCompleteElement = HTMLElementFactory.createHTMLULElement(
			"dynamic_autocomplete",
			{ display: "none" }
		);

		this._searchBoxElement = HTMLElementFactory.createHTMLSpanElement("search_box", undefined, undefined, {class: "collapsed"});

		this._searchInputContainerElement = HTMLElementFactory.createHTMLSpanElement(
			"search_input_container",
			undefined,
			undefined,
			{ class: "search_field" }
		);

		this._searchSelectsContainerElement = HTMLElementFactory.createHTMLSpanElement("search_selects_container");

		this._cancelSearchElement = HTMLElementFactory.createHTMLImgElement(
			"cancel_search",
			WebView360GlobalSettings.iconsPath + "cancel.png",
			{ class: "search_bar_button", },
			"Réinitialiser la carte",
			() => 
			{
				this.searchEngine.resetSearch(this);
			}
		);

		this._advancedSearchToggleElement = HTMLElementFactory.createHTMLSpanElement("adv_search_toggle", "Recherche avancée", undefined, {class: "search_bar_button"},
			() => 
			{
				this._advancedSearchToggleElement.classList.toggle("clicked");
				this._searchBoxElement.classList.toggle("collapsed");
			});
		this._advancedSearchToggleElement.appendChild(HTMLElementFactory.createHTMLSpanElement("filter_notifier", "Des filtres sont appliqués"));
		this._advancedSearchToggleElement.appendChild(HTMLElementFactory.createHTMLSpanElement("burger_button", undefined, undefined, {class: "hamburger-toggle"}));

		this._geoFieldsContainer = HTMLElementFactory.createHTMLSpanElement("geo_fields_container", undefined, undefined, {class: "search_fields_container"});
		this._activityFieldsContainer = HTMLElementFactory.createHTMLSpanElement("activity_fields_container", undefined, undefined, {class: "search_fields_container"});
	}

	public init(apiController: IAPIController, criterias?: SearchCriteria[]): Promise<void> 
	{
		this._apiController = apiController;
		let department = null;
		let category = null;
		if (criterias?.length > 0)
		{
			department = criterias.find((crit) => crit.field === "Departement")?.value;
			category = criterias.find((crit) => crit.field === "Categorie")?.value;
		}
		return Promise.all([
			this._fillSelectOptions(this._apiController, this._categorySelect),
			this._fillSelectOptions(this._apiController, this._departmentSelect),
			this._fillTypeSelectOptions(),
			department ? this._fillSelectOptions(this._apiController, this._citySelect, department) : Promise.resolve(),
			category ? this._fillSelectOptions(this._apiController, this._subCategorySelect, category) : Promise.resolve()
		]).then(() => this._initSearchFieldsWithConfigCriterias(criterias));
	}

	private _initSearchFieldsWithConfigCriterias(criterias: SearchCriteria[]): void 
	{
		this.searchEngine.initSearchFieldsWithCriteria(this, criterias, true);
	}
	
	public dismissAutoCompletion(): void
	{
		if (!this._debugAutoCompletion)
		{
			DomHelper.removeAll(this._dynamicAutoCompleteElement);
			this._dynamicAutoCompleteElement.style.display = "none";
		}
	}
	
	private _fillTypeSelectOptions(): void 
	{
		this._appendOptions(this._typeSelect, ["Coups de coeur", "Versions enrichies", "Vues aériennes"]);
	}

	private _fillSelectOptions(apiController: IAPIController, element: ISearchSelect, value?: string): Promise<void>
	{
		switch (element.field) 
		{
		case "Departement":
			return apiController.getDepartments()
				.then((departments: string[]) => 
				{
					element.options = departments;
					this._appendOptions(element, departments);
				});
		case "Ville":
			return apiController.getCities(value)
				.then((cities: string[]) => 
				{
					element.options = cities;
					this._appendOptions(element, cities);
				});
		case "Categorie":
			return apiController.getCategories()
				.then((categories: string[]) => 
				{
					element.options = categories;
					this._appendOptions(element, categories);
				});
		case "Sous_cat":
			return apiController.getSubCategories(value)
				.then((subCategories: string[]) => 
				{
					element.options = subCategories;
					this._appendOptions(element, subCategories);
				});
		}
	}

	private _appendOptions(elem: ISearchSelect, options: string[]): void 
	{
		const valueToInit = elem.htmlElement.value !== elem.placeholder && options.indexOf(elem.htmlElement.value) >= 0 ? elem.htmlElement.value : undefined;
		DomHelper.removeAll(elem.htmlElement);

		elem.htmlElement.appendChild(HTMLElementFactory.createHTMLOptionElement(elem.placeholder));

		options.forEach(option => 
		{
			elem.htmlElement.appendChild(HTMLElementFactory.createHTMLOptionElement(option));
		});

		if (valueToInit)
		{
			elem.htmlElement.value = valueToInit;
		}
	}

	public searchByText(value?: string): void 
	{
		this.searchEngine.searchByText(this, value);
	}

	public searchByValueField(criterias: SearchCriteria[], valueToSetInput?: string): void 
	{
		this.searchEngine.searchByValueField(this, criterias, valueToSetInput);
	}

	public searchByValueFieldStatic(data: VisitArray, criterias: SearchCriteria[]): VisitArray
	{
		return this.searchEngine.searchByValueFieldStatic(this, data, criterias);
	}

	public notifyFilter(enabled: boolean): void 
	{
		if (enabled)
		{
			this._searchBoxElement.classList.add("notify");
		}
		else
		{
			this._searchBoxElement.classList.remove("notify");
		}
	}

	public resetSelectsDiplay(): void
	{
		this._categorySelect.htmlElement.value = this._categorySelect.placeholder;
		this._subCategorySelect.htmlElement.value = this._subCategorySelect.placeholder;
		this._departmentSelect.htmlElement.value = this._departmentSelect.placeholder;
		this._citySelect.htmlElement.value = this._citySelect.placeholder;
		this._groupSelect.htmlElement.value = this._groupSelect.placeholder;
		this._typeSelect.htmlElement.value = this._typeSelect.placeholder;

		this._subCategorySelect.htmlElement.classList.add("hide");
		this._citySelect.htmlElement.classList.add("hide");
	}

	private _setSelectValue(select: ISearchSelect, value?: string, elemsToHide?: ISearchSelect[], elemsToShow?: ISearchSelect[], elemsToReinit?: ISearchSelect[], fromConfig?: boolean): Promise<void>
	{
		WebView360GlobalSettings.debug && console.debug(`[SearchFactory] _setSelectValue: ${select.field || select.htmlElement.id} - ${value}`);

		if (value)
		{
			select.htmlElement.value = value;
		}

		if (!fromConfig && elemsToHide?.length > 0) 
		{
			elemsToHide.forEach(elem => 
			{
				elem.htmlElement.classList.add("hide");
				elem.htmlElement.value = elem.placeholder;
			});
		}

		if (elemsToShow?.length > 0 && select.htmlElement.value !== select.placeholder) 
		{
			elemsToShow.forEach(elem => 
			{
				elem.htmlElement.classList.remove("hide");
			});
		}

		if (!fromConfig && elemsToReinit?.length > 0)
		{
			elemsToReinit.forEach(elem =>
			{
				elem.htmlElement.value = elem.placeholder;
			});
		}

		if (select.htmlElement.value === select.placeholder)
		{
			if (elemsToShow?.length > 0)
			{
				elemsToShow.forEach(elem => 
				{
					elem.htmlElement.classList.add("hide");
				});
			}
		}
		else
		{
			this.notifyFilter(true);
		}
		
		if (select.field === "Departement" && select.htmlElement.value !== select.placeholder && !(this._citySelect.options?.indexOf(this._citySelect.htmlElement.value) >= 0))
		{
			return this._fillSelectOptions(this._apiController, this._citySelect, select.htmlElement.value);
		}
		else if (select.field === "Categorie" && select.htmlElement.value !== select.placeholder && !(this._subCategorySelect.options?.indexOf(this._subCategorySelect.htmlElement.value) >= 0))
		{
			return this._fillSelectOptions(this._apiController, this._subCategorySelect, select.htmlElement.value);
		}
		return Promise.resolve();
	}

	private _searchFromSelect(select: ISearchSelect, subSelect?: ISearchSelect): void
	{
		WebView360GlobalSettings.debug && console.debug(`[SearchFactory] _searchFromSelect: ${select.field}`);

		if (select.htmlElement.value === select.placeholder)
		{
			this.searchEngine.resetSearch(this);
			select.setValue();
		}
		else if (!subSelect || subSelect.htmlElement.value === subSelect.placeholder)
		{
			this.searchByValueField([{value: select.htmlElement.value, field: select.field}]);
		}
		else
		{
			this.searchByValueField([{value: select.htmlElement.value, field: select.field}, {value: subSelect.htmlElement.value, field: subSelect.field}]);
		}
	}

	private _searchByAttribute() : void 
	{
		if (this._typeSelect.htmlElement.value !== this._typeSelect.placeholder)
		{
			const criteria = SearchHelper.attributesToSearchCriteria(this._typeSelect.htmlElement.value);
			criteria && this.searchByValueField([criteria], this._typeSelect.htmlElement.value);
		}
		else 
		{
			this.searchEngine.resetSearch(this);
			this._typeSelect.setValue();
		}
	}
	
	public getSearchTextElement(): HTMLInputElement 
	{
		return this._searchTextInputElement;
	}

	public getCategorySelect(): ISearchSelect 
	{
		return this._categorySelect;
	}

	public getSubCategorySelect(): ISearchSelect 
	{
		return this._subCategorySelect;
	}

	public getDepartmentSelect(): ISearchSelect 
	{
		return this._departmentSelect;
	}

	public getCitySelect(): ISearchSelect 
	{
		return this._citySelect;
	}

	public getGroupSelect(): ISearchSelect 
	{
		return this._groupSelect;
	}

	public getTypeSelect(): ISearchSelect 
	{
		return this._typeSelect;
	}

	public getDynamicAutoCompleteElement(): HTMLUListElement 
	{
		return this._dynamicAutoCompleteElement;
	}

	public render(): HTMLElement
	{
		this._searchInputContainerElement.appendChild(this._advancedSearchToggleElement);
		this._searchInputContainerElement.appendChild(this.getSearchTextElement());
		this._searchInputContainerElement.appendChild(this._cancelSearchElement);

		this._searchBoxElement.appendChild(this._searchInputContainerElement);
		this._searchBoxElement.appendChild(this.getDynamicAutoCompleteElement());

		this._activityFieldsContainer.appendChild(this.getCategorySelect().htmlElement);
		this._activityFieldsContainer.appendChild(this.getSubCategorySelect().htmlElement);

		this._geoFieldsContainer.appendChild(this.getDepartmentSelect().htmlElement);
		this._geoFieldsContainer.appendChild(this.getCitySelect().htmlElement);

		this._searchSelectsContainerElement.appendChild(this._activityFieldsContainer);
		this._searchSelectsContainerElement.appendChild(this._geoFieldsContainer);
		this._searchSelectsContainerElement.appendChild(this.getTypeSelect().htmlElement);

		this._searchBoxElement.appendChild(this._searchSelectsContainerElement);
		return this._searchBoxElement;
	}
}