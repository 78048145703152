import { DomHelper } from "./helpers/domHelper";
import { ISearchEngine, SearchEngine } from "./searchEngine";
import { HTMLElementFactory } from "./helpers/htmlElementFactory";
import { URLHelper } from "./helpers/urlHelper";
import { IPanomap, Panomap } from "./panomap";
import { WebView360GlobalSettings } from "./globalSettings";
import { APIController, IAPIController, WVDataBase } from "apiController";
import { DataController, IDataController, IVisitItem, VisitArray } from "dataController";
import { IVisitsRenderController, VisitsRenderController } from "visitsRenderController";
import { IMapController, MapController } from "mapController";
import { Drawer, IDrawer } from "drawer";
import { IScrollDisplayerController, ScrollDisplayerController } from "scrollDisplayerController";
import { SearchHelper } from "helpers/searchHelper";
import { ConfigTemplateController, IConfigTemplateController } from "configTemplateController";
import { IVisitsDrawer, VisitsDrawer } from "visitsDrawer";
import { IVisitDetailDrawer, VisitDetailDrawer } from "visitDetailDrawer";

export interface IVisit
{
	Categorie: string
	Date_PDV: string
	Departement: string;
	Drone: boolean;
	Drone_Iframe_VVP: string
	Favoris: boolean;
	Favoris_Drone: boolean;
	Favoris_enrichi: boolean;
	GPS_Lat: number;
	GPS_Lng: number;
	Groupe: string;
	Id: string;
	Lien_VE: string
	Nom: string;
	Page_GMap: string;
	Pano_ID: string;
	Pano_X: string;
	Pano_Y: string;
	Pano_Zoom: string;
	Pic_URI: string;
	Sous_cat: string;
	Ville: string;
	iFrame: string;
	ID_Overlay: string;
	ID_Gothru: string;
}

export interface IVisiteDetailled extends IVisit
{
	Adresse: string;
	Lien_VVP: string;
	Drone_Lien_VVP: string;
	Type_Navigation: string;
	Lien_Fiche_Etablissement: string;
	Lien_QRCode_VVP: string;
	Photographe: string;
	Lien_avis_Google: string;
	Stats_GSV: string;
	Stats_VE: string;
	Date_Mod: Date;
	Date_Publie: Date;
	Date_Livraison: Date;
	Client_Name: string;
	Phone_Nb: string;
	Email: string;
}

export type Plateform = "PM" | "PF" | "360WV";

export interface IWebView360Config
{
	url: URL; // current URL
	plateform: Plateform; // PM > Panomap | PF > Portfolio | 360WV 
	db: WVDataBase; // DB Type dr > Drone | ve > Visites Enrichis | bu > Business
	ed: boolean; // Ability to download panos image
	showAct: boolean; // Show activity on card
	showDep: boolean; // Show department on card
	showCity: boolean; // Show city on card
	showSearchZone: boolean; // Show the search zone
	showFs: boolean; // Show in fullscreen mode;
	showNewAttr: boolean; // Add new stamp on card
	showFavAttr: boolean; // Add favorite stamp on card
	showVAAttr: boolean; // Add drone stamp on card
	showVEAttr: boolean; // Add visite enrichi stamp on card
	clickEnabled: boolean; // Enable click on links on card
	limit: number; // To fix maximum number of records !! limite on template DB !!
	showDlButton: boolean; // Show dowload image button
	showCRMButton: boolean; // Show CRM button
	showRepButton: boolean; // Show report button
	showOverlayButton: boolean; // Show overlay button
	showGSVButton: boolean; // Show GSV button
	showCount: boolean; // to show count of visit corresponding to categories/departments/types
	/** search criteria */
	category: string;
	activity: string;
	department: string;
	city: string;
	group: string; // !! groupe on template DB !!
	attribute: string;
	id: string;
	/** specific to template */
	name: string;
	customTabName: string;
	customTabLink: string;
	customText: string;
	logoURL: string;
}

interface IWebView360
{
	init(): void;
}

/** To debug in console : globalThis["360wv-app"] */
class WebView360 implements IWebView360
{
	private _config: IWebView360Config;
	private _apiController: IAPIController;
	private _configTemplateController: IConfigTemplateController;
	private _drawer: IVisitsDrawer;
	private _visitDetail: IVisitDetailDrawer;
	private _searchEngine: ISearchEngine;
	private _dataController: IDataController;
	private _visitsRenderController: IVisitsRenderController;
	private _scrollDisplayController: IScrollDisplayerController;
	private _mapController: IMapController;
	private _panomap: IPanomap;

	private _rootContainer: HTMLElement;
	private _iframeContainer: HTMLElement;
	private _iframeItem: HTMLElement;

	private _byDefaultView: Plateform = null;

	public constructor()
	{
		if (!DomHelper.isMobileDevice())
		{
			this._mapController = new MapController(46.6882, 1.8308, 5);
		}
		this._dataController = new DataController();
		this._config = {} as IWebView360Config;
		this._searchEngine = new SearchEngine(this._dataController);
		this._drawer = new VisitsDrawer("opened", "left", "left-drawer");
		this._visitDetail = new VisitDetailDrawer("extended", "right", "visit-detail", false, true);
		this._apiController = new APIController();
		this._configTemplateController = new ConfigTemplateController(this._apiController);

		this._panomap = new Panomap(this._searchEngine, this._drawer, this._mapController, this._config);

		this._visitsRenderController = new VisitsRenderController(this._searchEngine, this._config, this._panomap);
		this._scrollDisplayController = new ScrollDisplayerController(this._visitsRenderController, this._drawer.getScrollableContainerElement());
	}

	public async init()
	{
		WebView360GlobalSettings.debug && console.debug("[WebView360] init");
		WebView360GlobalSettings.debug && console.time("[WebView360] ready");
		WebView360GlobalSettings.debug && console.time("[WebView360] domready");
		WebView360GlobalSettings.debug && console.time("[WebView360] dataready");

		await this._initParams();

		if (this._config.id) 
		{
			this._config.plateform = "PF";
		}

		this._apiController.setDB(this._config.db);

		this._drawer.setPlateformeMode(this._config.plateform, this._byDefaultView);
		this._searchEngine.setLimit(this._config.limit);
		this._searchEngine.setShowCount(this._config.showCount);

		const initDataPromise = this._initData();
		this._initDom();

		const initComponentsPromise = this._initComponents();

		const domReadyPromise = DomHelper.domReady()
			.then(() =>
			{
				if (WebView360GlobalSettings.debug)
				{
					console.debug("[WebView360] _init -> DOM ready");
					console.timeEnd("[WebView360] domready");
				}
			})
			.then(() => this._initRootElements())
			.then(() => this._initDomListeners())
			.then(() => this._render());

		this._applyConfig();

		initDataPromise
			.then(() => domReadyPromise)
			.then(() => initComponentsPromise)
			.then(() =>
			{
				this._visitsRenderController.registerHandler();
				this._searchEngine.setOnDataChangeListener((fromMap: boolean) =>
				{
					WebView360GlobalSettings.debug && console.debug("[SearchEngine] _onDataChanged");

					if (this._config.plateform !== "PF")
					{
						this._mapController.initMarkerClusters(VisitArray.getMarkers(this._searchEngine.getVisitsFiltered()));
						!fromMap && (!this._config.id || this._searchEngine.getVisitsFiltered().length > 1) && this._mapController.fitMapToMarkers();
					}
					this._visitsRenderController.showVisits();
				});
			})
			.then(() =>
			{
				if (WebView360GlobalSettings.debug)
				{
					console.debug("[WebView360] _initData -> data ready");
					console.timeEnd("[WebView360] dataready");
				}

				this._searchEngine.sort();
				this._visitsRenderController.showVisits();
				this._mapController && !this._config.id && this._mapController.fitMapToMarkers();
				this._searchEngine.computeCategoryCount();
				WebView360GlobalSettings.debug && console.timeEnd("[WebView360] ready");
			});
	}

	private _initData(): Promise<VisitArray | void>
	{
		WebView360GlobalSettings.debug && console.debug("[WebView360] _initData");

		const searchCriteriasFromConfig = SearchHelper.configToSearchCriteria(this._config);
		WebView360GlobalSettings.debug && searchCriteriasFromConfig?.length > 0 && console.debug("[WebView360] _initData > searchCriterias from config");
		WebView360GlobalSettings.debug && searchCriteriasFromConfig?.length > 0 && console.debug(searchCriteriasFromConfig);

		if (this._config.id)
		{
			return this._apiController.getVisit(this._config.id)
				.then(visit =>
				{
					this._dataController.init(this._apiController, this._config.plateform, this._visitsRenderController.buildVisitItem.bind(this._visitsRenderController), this._mapController);
					this._visitDetail.initDrawerDOM(JSON.parse(visit) as IVisit);
				});
		}

		return this._dataController.initAndGetVisits(this._apiController, this._config.plateform, this._visitsRenderController.buildVisitItem.bind(this._visitsRenderController), this._mapController, (data: VisitArray) =>
		{
			const isFirstResult = this._searchEngine.getVisitsFiltered().length === 0;

			if (searchCriteriasFromConfig?.length > 0 && this._panomap.getSearchFactory())
			{
				data = this._panomap.getSearchFactory().searchByValueFieldStatic(data, searchCriteriasFromConfig);
			}

			this._searchEngine.initByStream(data);
			if (isFirstResult)
			{
				this._config.plateform !== "PF" && this._mapController.initMarkerClusters(VisitArray.getMarkers(this._searchEngine.getVisitsFiltered()));
				this._visitsRenderController.showVisits(undefined, undefined, true);
			}
			else if (this._config.limit > 0)
			{
				this._config.plateform !== "PF" && this._mapController.initMarkerClusters(VisitArray.getMarkers(this._searchEngine.getVisitsFiltered()));
			}
			else
			{
				this._config.plateform !== "PF" && this._mapController.addMarkers(VisitArray.getMarkers(data));
			}
		});
	}

	private _initDom(): void
	{
		WebView360GlobalSettings.debug && console.debug("[WebView360] _initDom");
		this._initDomStyles();
	}

	private _initDomStyles(): void
	{
		document.head.appendChild(HTMLElementFactory.createHTMLLinkElement(
			WebView360GlobalSettings.cssPath + "style.css",
			"text/css",
			"stylesheet"
		));
		document.getElementsByTagName("head")[0].appendChild(HTMLElementFactory.createHTMLStyleElement("styles_360wv"));
	}

	private _initRootElements()
	{
		this._iframeContainer = document.getElementById("iframe_container");
		this._iframeItem = document.getElementById("iframe_item");
		this._rootContainer = document.getElementById("content");
		this._visitsRenderController.setIFrameContainer(this._iframeItem);

		this._iframeContainer.appendChild(HTMLElementFactory.createHTMLImgElement("closeIframe", WebView360GlobalSettings.iconsPath + "close.png"));
	}

	private _initDomListeners(): void
	{
		/**
			* OnKeyDown
		* When the user hit the escape key and that the Virtual Visits iFrame is open, that
		* close the iFrame.
		*/
		document.addEventListener("keydown", (event) =>
		{
			if (event.keyCode === 27)
			{
				if (this._iframeContainer != null)
				{
					this._iframeContainer.classList.remove("showed");

					DomHelper.isOnIframe() && document.exitFullscreen();
				}
			}
		});

		this._iframeContainer.onclick = () =>
		{
			this._iframeContainer.classList.remove("showed");
			DomHelper.isOnIframe() && document.exitFullscreen();
		};

		this._scrollDisplayController.init();
	}

	private _initComponents(): Promise<void>
	{
		WebView360GlobalSettings.debug && console.debug("[WebView360] _initComponents");
		return Promise.all([
			this._panomap.init(this._apiController),
		]).then(() => (WebView360GlobalSettings.debug && console.debug("[WebView360] _initComponents -> ready")));
	}

	private _initParams(): Promise<void>
	{
		WebView360GlobalSettings.debug && console.debug("[WebView360] _initParams");
		/**
		 * searchParams.get for string
		 * getUrlParam for boolean
		 */
		let returnPromise = null;

		this._config.url = new URL(window.location.href);
		this._config.plateform = this._config.url.searchParams.get("plt") as Plateform || "360WV"; // PM > Panomap | PF > Portfolio | 360WV 
		this._config.db = this._config.url.searchParams.get("db") as WVDataBase || "";
		this._config.ed = URLHelper.getUrlParam("ed", false);
		this._config.showAct = URLHelper.getUrlParam("act_caption", true);
		this._config.showDep = URLHelper.getUrlParam("dep_caption", true);
		this._config.showCity = URLHelper.getUrlParam("city_caption", true);
		this._config.showSearchZone = URLHelper.getUrlParam("search_zone", true);
		this._config.showFs = URLHelper.getUrlParam("fs", false);
		this._config.showNewAttr = URLHelper.getUrlParam("show_newattr", true);
		this._config.showFavAttr = URLHelper.getUrlParam("show_favattr", true);
		this._config.showVAAttr = URLHelper.getUrlParam("show_vaattr", true);
		this._config.showVEAttr = URLHelper.getUrlParam("show_veattr", true);
		this._config.clickEnabled = URLHelper.getUrlParam("click_enabled", true);
		this._config.limit = URLHelper.getUrlParam("limit", 0);
		this._config.showDlButton = URLHelper.getUrlParam("show_dl_button", false);
		this._config.showCRMButton = URLHelper.getUrlParam("show_crm_button", false);
		this._config.showRepButton = URLHelper.getUrlParam("show_rep_button", false);
		this._config.showOverlayButton = URLHelper.getUrlParam("show_overlay_button", false);
		this._config.showGSVButton = URLHelper.getUrlParam("show_gsv_button", false);
		this._config.showCount = URLHelper.getUrlParam("show_count", false);
		this._config.category = this._config.url.searchParams.get("cat_parent") || null;
		this._config.activity = this._config.url.searchParams.get("cat_child") || null;
		this._config.department = this._config.url.searchParams.get("dep") || null;
		this._config.city = this._config.url.searchParams.get("city") || null;
		this._config.group = this._config.url.searchParams.get("group") || null;
		this._config.attribute = this._config.url.searchParams.get("attr") || null;
		this._config.id = this._config.url.searchParams.get("id") || null;

		if ((this._config.name = this._config.url.searchParams.get("tpt")))
		{
			returnPromise = this._configTemplateController.fillConfigFromTemplate(this._config, this._config.name)
				.then((config) =>
				{
					Object.keys(this._config).forEach((key) =>
					{
						this._config[key] = typeof config[key] !== "undefined" && config[key] !== null ? config[key] : this._config[key];
						delete config[key];
					});

					Object.keys(config).forEach((key) => 
					{
						this._config[key] = typeof config[key] !== "undefined" && config[key] !== null ? config[key] : this._config[key];
						delete config[key];
					});
					this._byDefaultView = this._config.plateform;
				});
		}
		if (DomHelper.isMobileDevice() && !this._config.id)
		{
			this._config.plateform = "PF";
		}
		return returnPromise || Promise.resolve();
	}

	private _applyConfig(): void
	{
		WebView360GlobalSettings.debug && console.debug("[WebView360] _applyConfig");

		if (this._config.showFs)
		{
			this._panomap.setFullScreen(true);
		}
	}

	private _render(): void
	{
		WebView360GlobalSettings.debug && console.debug("[WebView360] _render");

		this._panomap.render();
		!this._config.showFs && this._rootContainer.appendChild(this._configTemplateController.getHTMLTemplateHeader(this._config, this._drawer));
		if (this._config.id)
		{
			this._rootContainer.appendChild(this._visitDetail.render());
		}
		else
		{
			this._rootContainer.appendChild(this._config.plateform === "PF" ? this._drawer.render() : this._panomap.getHTMLElement());
		}
	}
}

const core = new WebView360();
core.init();

if (WebView360GlobalSettings.debug)
{
	window["360WV-core"] = core;
}