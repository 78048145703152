export type PromiseResolve<T> = (value: T | Promise<T>) => void;
export type PromiseReject = (reason?: unknown) => void;
export type PromiseExecutor<T> = (resolve: PromiseResolve<T>, reject: PromiseReject) => void;

export interface IDeferredObject<T>
{
	/**
	 * Promise instance of the deferred object.
	 */
	promise: Promise<T>;

	/**
	 * Resolves the derived promise with the value or another promise.
	 */
	resolve: PromiseResolve<T>;

	/**
	 * Rejects the derived promise with the reason.
	 */
	reject: PromiseReject;
}

/**
 * Returns a new instance of deferred object.
 */
export type DeferVerb = <T>(executor?: PromiseExecutor<T>) => IDeferredObject<T>;

/**
 * Standard implementation of the interface IDeferredObject.
 */
export class DeferredObject<T> implements IDeferredObject<T>
{
	public promise: Promise<T>;
	public resolve: PromiseResolve<T>;
	public reject: PromiseReject;

	/**
	 * Constructor of the DeferrerObject. An optional executor can be specified.
	 * @param executor
	 */
	public constructor(executor?: PromiseExecutor<T>)
	{
		this.promise = new Promise((resolve, reject) =>
		{
			this.resolve = resolve;
			this.reject = reject;

			executor?.(resolve, reject);
		});

		this.promise.catch((reason) =>
		{
			console.debug(reason);
		});
	}

	public static create<T>(executor?: PromiseExecutor<T>): DeferredObject<T>
	{
		return new DeferredObject<T>(executor);
	}
}