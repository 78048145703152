import { WebView360GlobalSettings } from "globalSettings";

export interface IAPICacheController
{
	getCategories(): string[];
	setCategories(categories: string[]): void;
	hasCategoriesCached(): boolean;

	getActivities(category: string): string[];
	setActivities(activities: string[], category: string): void;
	hasActivitiesCached(category: string): boolean;

	getDepartments(): string[];
	setDepartments(departments: string[]): void;
	hasDepartmentsCached(): boolean;

	getCities(department: string): string[];
	setCities(cities: string[], department: string): void;
	hasCitiesCached(department: string): boolean;
}

export class APICacheController implements IAPICacheController
{
	private _categories: string[];
	private _activities: object;
	private _departments: string[];
	private _cities: object;

	public constructor()
	{
		this._categories = [];
		this._activities = {};
		this._departments = [];
		this._cities = {};
	}

	public hasCategoriesCached(): boolean 
	{
		return this._categories.length > 0;
	}

	public hasActivitiesCached(category: string): boolean 
	{
		return Object.keys(this._activities).includes(category);
	}

	public hasDepartmentsCached(): boolean 
	{
		return this._departments.length > 0;
	}

	public hasCitiesCached(department: string): boolean 
	{
		return Object.keys(this._cities).includes(department);
	}

	public getCategories(): string[] 
	{
		WebView360GlobalSettings.debug && console.debug("[APICacheController] getCategories");

		return this._categories;
	}
	
	public setCategories(categories: string[]): void 
	{
		this._categories = categories;
	}
	
	public getActivities(category: string): string[] 
	{
		WebView360GlobalSettings.debug && console.debug(`[APICacheController] getActivities: ${category}`);

		return Object.keys(this._activities).includes(category) && this._activities[category];
	}
	
	public setActivities(activities: string[], category: string): void 
	{
		this._activities[category] = activities;
	}
	
	public getDepartments(): string[] 
	{
		WebView360GlobalSettings.debug && console.debug("[APICacheController] getDepartments");

		return this._departments;
	}
	
	public setDepartments(departments: string[]): void 
	{
		this._departments = departments;
	}
	
	public getCities(department: string): string[] 
	{
		WebView360GlobalSettings.debug && console.debug(`[APICacheController] getCities: ${department}`);

		return Object.keys(this._cities).includes(department) && this._cities[department];
	}
	
	public setCities(cities: string[], department: string): void 
	{
		this._cities[department] = cities;
	}
}