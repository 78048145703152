export class DomHelper
{
	public static domReady(): Promise<void>
	{
		if (document.readyState === "complete")
		{
			return Promise.resolve();
		}
		else
		{
			return new Promise((resolve) =>
			{
				window.addEventListener("load", () => resolve());
			});
		}
	}

	public static removeAll(element: HTMLElement, selector: string = "*"): void
	{
		element.querySelectorAll(selector).forEach(n => n.remove());
	}

	public static getElementSelector(element: HTMLElement): string
	{
		if (element.tagName.toLowerCase() == "html")
			return "HTML";
		let ret = element.tagName;
		if (element.className) 
		{
			const classes = element.className.split(/\s/);
			for (let i = 0; i < classes.length; i++) 
			{
				ret += "." + classes[i];
			}
		}
		else if (element.id)
		{
			ret += "#" + element.id;
		}

		return ret;
	}

	public static isMobileDevice(): boolean
	{
		return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
	}

	public static isOnIframe(): boolean
	{
		return window.location !== window.parent.location;
	}

	public static getSelectedValues(select: HTMLSelectElement): string[]
	{
		const result: string[] = [];
		const options = select && select.options;
		let opt;

		for (let i=0, iLen=options.length; i<iLen; i++) 
		{
			opt = options[i];

			if (opt.selected) 
			{
				result.push(opt.value || opt.text);
			}
		}
		return result;
	}

	public static isUnderDrawer(drawerElement: HTMLElement, markerPosition: {x: number, y: number}): boolean
	{
		return drawerElement.getBoundingClientRect().right == drawerElement.clientWidth && markerPosition.x < drawerElement.clientWidth;
	}
}