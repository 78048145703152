import { Plateform } from "core";
import { Drawer, DrawerState } from "drawer";
import { WebView360GlobalSettings } from "globalSettings";
import { HTMLElementFactory } from "./helpers/htmlElementFactory";

export interface IVisitsDrawer extends Drawer {
	setTemplateHeaderButtons(listButton: HTMLElement, mapButton: HTMLElement, plateform: Plateform);
	setPlateformeMode(plateform: Plateform, byDefaultView?: Plateform): void;
	setOnMoveListenerVisitDrawer(onOpen: (fromState) => void, onClose: (fromState) => void, onExtend: (fromState) => void): void
}

export class VisitsDrawer extends Drawer 
{
	private _templateHeaderListButton: HTMLElement;
	private _templateHeaderMapButton: HTMLElement;

	public setTemplateHeaderButtons(listButton: HTMLElement, mapButton: HTMLElement, plateform: Plateform): void 
	{
		this._templateHeaderListButton = listButton;
		this._templateHeaderMapButton = mapButton;

		if (this._state === "extended") 
		{
			this._templateHeaderMapButton?.classList.remove("selected");
			this._templateHeaderListButton?.classList.add("selected");
		}
		else 
		{
			this._templateHeaderMapButton?.classList.add("selected");
			this._templateHeaderListButton?.classList.remove("selected");
		}

		if (plateform && plateform == "PM" && this._templateHeaderListButton) 
		{
			this._templateHeaderListButton.style.display = "none";
		}

		if (plateform && plateform == "PF" && this._templateHeaderMapButton) 
		{
			this._templateHeaderMapButton.style.display = "none";
		}
	}

	public setPlateformeMode(plateform: Plateform, byDefaultView?: Plateform): void 
	{
		this._state = byDefaultView && byDefaultView === "PF" ? "extended" : "opened";
		if (plateform == "360WV") 
		{
			this._extendButtonCondition = true;
		}
		else if (plateform == "PF") 
		{
			this._hideButtons = true;
			this._state = "extended";
		}
		else if (plateform == "PM") 
		{
			this._extendButtonCondition = false;
		}

	}

	public setOnMoveListenerVisitDrawer(onOpen: (fromState) => void, onClose: (fromState) => void, onExtend: (fromState) => void): void 
	{
		const onOpenH = (fromState) => 
		{
			this._templateHeaderListButton && this._templateHeaderListButton.classList.remove("selected");
			this._templateHeaderMapButton && this._templateHeaderMapButton.classList.add("selected");
			onOpen && onOpen(fromState);
		};

		const onCloseH = (fromState) => 
		{
			this._templateHeaderListButton && this._templateHeaderListButton.classList.remove("selected");
			this._templateHeaderMapButton && this._templateHeaderMapButton.classList.add("selected");
			onClose && onClose(fromState);
		};

		const onExtendH = (fromState) => 
		{
			this._templateHeaderListButton && this._templateHeaderListButton.classList.add("selected");
			this._templateHeaderMapButton && this._templateHeaderMapButton.classList.remove("selected");
			onExtend && onExtend(fromState);
		};
		this.setOnMoveListener(onOpenH, onCloseH, onExtendH);
	}


}