import { IDataController, IVisitItem, VisitArray } from "dataController";
import { WebView360GlobalSettings } from "globalSettings";
import { DomHelper } from "helpers/domHelper";
import { SearchHelper } from "helpers/searchHelper";
import { StringHelper } from "helpers/stringHelper";
import { ISearchFactory, SearchFactory } from "searchFactory";
import { IVisit } from "./core";

export type SearchCriteria = {value: unknown, field: string};

export interface ISearchEngine
{
	computeCategoryCount();
	initByStream(data: VisitArray);
	getVisitsFiltered(): VisitArray
	resetSearch(factory: ISearchFactory): void
	searchByText(factory: ISearchFactory, value?: string) : void
	searchByValueField(factory: ISearchFactory, criterias: SearchCriteria[], valueToSetInput?: string): void
	searchByValueFieldStatic(factory: ISearchFactory, data: VisitArray, criterias: SearchCriteria[]): VisitArray
	sort(): void
	sortVisits(sortFunction: (a: IVisitItem, b: IVisitItem) => number): void
	filterVisits(filterFunction: (value: IVisitItem) => unknown): void
	filterVisitsByMap(filterFunction: (value: IVisitItem) => unknown, triggerOnDataChanged?: boolean): void
	init(): void
	setOnDataChangeListener(onDataChanged: (fromMap?:boolean) => void): void
	createSearchFactory(name: string): ISearchFactory
	initSearchFieldsWithCriteria(factory: ISearchFactory, criterias: SearchCriteria[], fromConfig?: boolean): void
	setLimit(limit: number): void
	setShowCount(showCount: boolean): void
	getSearchFactory(): ISearchFactory
}

export interface ISearchItem
{
	id: string;
	visit: IVisit;
	marker: google.maps.Marker;
}

export interface IAutoCompletionItem
{
	value: string;
	field: string;
	parentItem?: IAutoCompletionItem; 
}

export class SearchEngine implements ISearchEngine
{
	private _dataController: IDataController;
	private _visitsFiltered: VisitArray;
	private _visitsFilteredOnMap: VisitArray;
	private _onDataChanged: (fromMap?:boolean) => void;
	private _autoCompletionList: IAutoCompletionItem[];
	private _limit: number;
	private _factory: SearchFactory;
	private _showCount: boolean;

	public constructor(dataController: IDataController)
	{
		this._dataController = dataController;
		this._visitsFiltered = [];
	}
	public computeCategoryCount(): void
	{
		if (this._showCount)
		{
			this._factory.getCategorySelect().htmlElement.children[0].innerHTML.indexOf("(") < 0 && this._factory.getCategorySelect().htmlElement.childNodes.forEach((elem: HTMLElement, id: number) => 
			{
				const cat = elem.innerText;
				if (id === 0)
				{
					elem.innerText = cat + ` (${this._dataController.getVisits().length})`;
				}
				else
				{
					elem.innerText = cat + ` (${this._dataController.getVisits().filter((visit) => 
					{
						return visit.visit.Categorie === cat;
					}).length})`;
				}
			});
	
			this._factory.getDepartmentSelect().htmlElement.children[0].innerHTML.indexOf("(") < 0 && this._factory.getDepartmentSelect().htmlElement.childNodes.forEach((elem: HTMLElement, id: number) => 
			{
				const dep = elem.innerText;
				if (id === 0)
				{
					elem.innerText = dep + ` (${this._dataController.getVisits().length})`;
				}
				else
				{
					elem.innerText = dep + ` (${this._dataController.getVisits().filter((visit) => 
					{
						return visit.visit.Departement === dep;
					}).length})`;
				}
			});
	
			this._factory.getTypeSelect().htmlElement.children[0].innerHTML.indexOf("(") < 0 && this._factory.getTypeSelect().htmlElement.childNodes.forEach((elem: HTMLElement, id: number) => 
			{
				const attr = SearchHelper.attributesToSearchCriteria(elem.innerText);
				
				if (id === 0)
				{
					elem.innerText = elem.innerText + ` (${this._dataController.getVisits().length})`;
				}
				else
				{
					elem.innerText = elem.innerText + ` (${this._dataController.getVisits().filter((visit) => 
					{
						return attr && ((attr.value === "#NOTNULL#" && visit.visit[attr.field]) || visit.visit[attr.field] === attr.value);
					}).length})`;
				}
			});
	
			this._factory.getCitySelect().htmlElement.childNodes.length > 1 && this._factory.getCitySelect().htmlElement.children[0].innerHTML.indexOf("(") < 0 && this._factory.getCitySelect().htmlElement.childNodes.forEach((elem: HTMLElement, id: number) => 
			{
				const city = elem.innerText;
				if (id === 0)
				{
					elem.innerText = city + ` (${this._dataController.getVisits().filter((visit) => 
					{
						return visit.visit.Departement === this._factory.getDepartmentSelect().htmlElement.value;
					}).length})`;
				}
				else
				{
					elem.innerText = city + ` (${this._dataController.getVisits().filter((visit) => 
					{
						return visit.visit.Ville === city;
					}).length})`;
				}
			});
	
			this._factory.getSubCategorySelect().htmlElement.childNodes.length > 1 && this._factory.getSubCategorySelect().htmlElement.children[0].innerHTML.indexOf("(") < 0 && this._factory.getSubCategorySelect().htmlElement.childNodes.forEach((elem: HTMLElement, id: number) => 
			{
				const sCat = elem.innerText;
				if (id === 0)
				{
					elem.innerText = sCat + ` (${this._dataController.getVisits().filter((visit) => 
					{
						return visit.visit.Categorie === this._factory.getCategorySelect().htmlElement.value;
					}).length})`;
				}
				else
				{
					elem.innerText = sCat + ` (${this._dataController.getVisits().filter((visit) => 
					{
						return visit.visit.Sous_cat === sCat;
					}).length})`;
				}
			});
		}
	}

	public setLimit(limit: number): void
	{
		this._limit = limit;
	}

	public setShowCount(showCount: boolean): void
	{
		this._showCount = showCount;
	}
	
	public createSearchFactory(name: string): ISearchFactory 
	{
		return this._factory = new SearchFactory(this, name);
	}
	
	public setOnDataChangeListener(onDataChanged: (fromMap?:boolean) => void) : void
	{
		this._onDataChanged = onDataChanged;
	}

	public init(): void
	{
		WebView360GlobalSettings.debug && console.debug("[SearchEngine] init");

		this._initVisitsFiltered();
	}

	public initByStream(data: VisitArray): void
	{
		this._visitsFiltered = this._visitsFiltered.concat(data);
		this._visitsFilteredOnMap = this._visitsFiltered;
		this._checkAndApplyLimit();
		WebView360GlobalSettings.debug && console.debug(`[SearchEngine] init by stream -> length: ${this._visitsFiltered.length}`);
	}

	private _matchCriterias(visit: IVisit, criterias: SearchCriteria[]): boolean
	{
		for (const criteria of criterias)
		{
			if (!this._matchCriteria(visit, criteria))
			{
				return false;
			}
		}

		return true;
	}

	private _matchCriteria(visit: IVisit, criteria: SearchCriteria): boolean
	{
		if (SearchEngine.isSearchField(criteria.field) && criteria.value)
		{
			if (criteria.field == "Departement" && (criteria.value as string).indexOf(";") >= 0)
			{
				let isMatching = false;
				(criteria.value as string).split(";").forEach((item) =>
				{
					if (this._matchCriteria(visit, {field: criteria.field, value: item}))
					{
						isMatching = true;
					}
				});
				return isMatching;
			}
			else if (criteria.value === "#NOTNULL#" && visit[criteria.field])
			{
				return true;
			}
			else if (typeof visit[criteria.field] === "string")
			{
				return StringHelper.accentFold((visit[criteria.field] as string).toLowerCase()) == (StringHelper.accentFold((criteria.value as string).toLowerCase()));
			}
			else
			{
				return visit[criteria.field] === criteria.value;
			}
		}
	}

	public initSearchFieldsWithCriteria(factory: ISearchFactory, criterias: SearchCriteria[], fromConfig: boolean = false): void
	{
		// TO REFACTOR - UPDATE META
		let title: string = "1001 Visites - ";
		let desc: string = "";
		let kws: string = "";
		let cat, subCat, dep, city, type;

		criterias.forEach((criteria) =>
		{
			
			switch (criteria.field)
			{
			case "Nom":
				factory.getSearchTextElement().value = criteria.value as string;
				kws += criteria.value + ", ";
				break;
			case "Departement":
				dep = (criteria.value as string);
				kws += criteria.value + ", ";
				if ((criteria.value as string).indexOf(";") >= 0)
				{
					factory.getDepartmentSelect().setValues((criteria.value as string).split(";"), fromConfig);
					this.computeCategoryCount();
				}
				else
				{
					factory.getDepartmentSelect().setValue(criteria.value as string, criterias.find((crit) => crit.field === "Ville")?.value as string, fromConfig)
						.then(() =>
						{
							this.computeCategoryCount();
						});
				}
				break;
			case "Ville":
				city = criteria.value as string;
				kws += criteria.value + ", ";
				factory.getCitySelect().setValue(criteria.value as string, undefined, fromConfig);
				break;
			case "Categorie":
				cat = criteria.value as string;
				kws += criteria.value + ", ";
				factory.getCategorySelect().setValue(criteria.value as string, criterias.find((crit) => crit.field === "Sous_cat")?.value as string, fromConfig)
					.then(() =>
					{
						this.computeCategoryCount();
					});
				break;
			case "Sous_cat":
				subCat = criteria.value as string;
				kws += criteria.value + ", ";
				factory.getSubCategorySelect().setValue(criteria.value as string, undefined, fromConfig);
				break;
			case "Favoris":
			case "Lien_VE":
			case "Drone":
				type = SearchHelper.searchCriteriaToAttribute(criteria);
				kws += type + ", ";
				factory.getTypeSelect().setValue(type, undefined, fromConfig);
				break;
			}
			
		});

		title += (type || "Visites virtuelles") + " " + (cat ? "- " + cat + " " : "") + (subCat ? "- " + subCat + " " : "") + (dep ? "- " + dep + " " : "") + (city ? "- " + city : "");
		desc = "Découvrez sur 1001 Visites les " + (type || "visites virtuelles") + " " + (cat ? "des activités " + cat + " " : "") + (subCat ? "des sous-activités " + subCat + " " : "") + (dep ? "du département " + dep + " " : "") + (city ? "de la ville de " + city : "")
		+ ". Naviguez sur la carte pour retrouver les meilleurs visites 360° repertoriées dans la base de données 1001 visites.";

		(document.head.getElementsByTagName("TITLE")[0] as HTMLElement).innerText = title;
		document.querySelector("meta[name=\"description\"]").setAttribute("content", desc);
		document.querySelector("meta[name=\"keywords\"]").setAttribute("content", kws);

	}

	public searchByValueField(factory: ISearchFactory, criterias: SearchCriteria[], valueToSetInput?: string): void
	{
		WebView360GlobalSettings.debug && console.debug(`[SearchEngine] searchByValueField: ${criterias}`);

		if (valueToSetInput || criterias[criterias.length - 1].value) 
		{
			factory.getSearchTextElement().value = valueToSetInput || (typeof criterias[criterias.length - 1].value === "string" ? criterias[criterias.length - 1].value : "") as string; // set last criteria value

			this.initSearchFieldsWithCriteria(factory, criterias);
			this.filterVisits((visit: IVisitItem) => 
			{
				return (this._matchCriterias(visit.visit, criterias));
			});
		}
	}

	public searchByValueFieldStatic(factory: ISearchFactory, data: VisitArray, criterias: SearchCriteria[]): VisitArray
	{
		WebView360GlobalSettings.debug && console.debug(`[SearchEngine] searchByValueFieldStatic: ${criterias}`);

		if (criterias.length > 0) 
		{
			data = data.filter((visit: IVisitItem) => 
			{
				return (this._matchCriterias(visit.visit, criterias));
			});
		}
		return data;
	}

	public searchByText(factory: ISearchFactory, value?: string) : void
	{
		WebView360GlobalSettings.debug && console.debug(`[SearchEngine] searchByText: ${value}`);

		this._autoCompletionList = [];
		DomHelper.removeAll(factory.getDynamicAutoCompleteElement());
		
		if (value)
		{
			this.filterVisits((visit: IVisitItem) => 
			{
				if (StringHelper.accentFold(visit.visit.Nom.toLowerCase()).indexOf(StringHelper.accentFold(value.toLowerCase())) > -1) 
				{
					if (!this._autoCompletionList.find((item) => item.value === visit.visit.Nom && item.field === "Nom")) 
					{
						this._autoCompletionList.push({ value: visit.visit.Nom, field: "Nom"});
					}
				}
				else if (StringHelper.accentFold(visit.visit.Departement.toLowerCase()).indexOf(StringHelper.accentFold(value.toLowerCase())) > -1) 
				{
					if (!this._autoCompletionList.find((item) => item.value === visit.visit.Departement && item.field === "Departement")) 
					{
						this._autoCompletionList.push({ value: visit.visit.Departement, field: "Departement"});
					}
				}
				else if (StringHelper.accentFold(visit.visit.Ville.toLowerCase()).indexOf(StringHelper.accentFold(value.toLowerCase())) > -1) 
				{
					if (!this._autoCompletionList.find((item) => item.value === visit.visit.Ville && item.field === "Ville")) 
					{
						this._autoCompletionList.push({ value: visit.visit.Ville, field: "Ville", parentItem: {value: visit.visit.Departement, field: "Departement"}});
					}
				}
				else if (StringHelper.accentFold(visit.visit.Categorie.toLowerCase()).indexOf(StringHelper.accentFold(value.toLowerCase())) > -1) 
				{
					if (!this._autoCompletionList.find((item) => item.value === visit.visit.Categorie && item.field === "Categorie")) 
					{
						this._autoCompletionList.push({ value: visit.visit.Categorie, field: "Categorie"});
					}
				}
				else if (StringHelper.accentFold(visit.visit.Sous_cat.toLowerCase()).indexOf(StringHelper.accentFold(value.toLowerCase())) > -1) 
				{
					if (!this._autoCompletionList.find((item) => item.value === visit.visit.Sous_cat && item.field === "Sous_cat")) 
					{
						this._autoCompletionList.push({ value: visit.visit.Sous_cat, field: "Sous_cat", parentItem: {value: visit.visit.Categorie, field: "Categorie"}});
					}
				}
				return (StringHelper.accentFold(visit.visit.Nom.toLowerCase()).indexOf(StringHelper.accentFold(value.toLowerCase())) > -1 ||
					StringHelper.accentFold(visit.visit.Departement.toLowerCase()).indexOf(StringHelper.accentFold(value.toLowerCase())) > -1 ||
					StringHelper.accentFold(visit.visit.Ville.toLowerCase()).indexOf(StringHelper.accentFold(value.toLowerCase())) > -1 ||
					StringHelper.accentFold(visit.visit.Categorie.toLowerCase()).indexOf(StringHelper.accentFold(value.toLowerCase())) > -1 ||
					StringHelper.accentFold(visit.visit.Sous_cat.toLowerCase()).indexOf(StringHelper.accentFold(value.toLowerCase())) > -1);
			});
			factory.notifyFilter(true);
		}
		else
		{
			this.resetSearch(factory);
		}

		this._refreshDynamicAutoComplete(factory, value);
	}

	public resetSearch(factory: ISearchFactory): void 
	{
		WebView360GlobalSettings.debug && console.debug("[SearchEngine] resetSearch");

		factory.getSearchTextElement().value = "";
		this.filterVisits(() => true);
		factory.notifyFilter(false);
		factory.resetSelectsDiplay();
	}

	public filterVisitsByMap(filterFunction: (value: IVisitItem) => unknown, triggerOnDataChanged: boolean = true): void 
	{
		WebView360GlobalSettings.debug && console.debug("[SearchEngine] filterVisitsByMap");

		if (this._visitsFiltered?.length > 0)
		{
			this._visitsFilteredOnMap = this._visitsFiltered.filter(filterFunction);
		}
		else
		{
			console.warn("[SearchEngine] No data source to filter");
			return ;
		}

		this._checkAndApplyLimit();

		if (triggerOnDataChanged)
		{
			this._onDataChanged(true);
		}
	}

	public filterVisits(filterFunction: (value: IVisitItem) => unknown): void
	{
		WebView360GlobalSettings.debug && console.debug("[SearchEngine] filterVisits");

		if (this._dataController.getVisits())
		{
			this._visitsFiltered = this._dataController.getVisits().filter(filterFunction);
		}
		else if (this._dataController.getVisitsByStream())
		{
			this._visitsFiltered = this._dataController.getVisitsByStream().filter(filterFunction);
		}
		else
		{
			console.error("[SearchEngine] No data source to filter");
		}
		
		this._visitsFilteredOnMap = this._visitsFiltered;
		this._checkAndApplyLimit();
		this._onDataChanged();
	}

	private _checkAndApplyLimit(): void
	{
		if (this._limit > 0)
		{
			this._visitsFilteredOnMap = this._visitsFilteredOnMap.sort((a, b) => Date.parse(b.visit.Date_PDV) - Date.parse(a.visit.Date_PDV));
			this._visitsFilteredOnMap.splice(this._limit);
		}
	}
	
	public sort(): void
	{
		this.sortVisits((a, b) => Date.parse(b.visit.Date_PDV) - Date.parse(a.visit.Date_PDV));
	}

	public sortVisits(sortFunction: (a: IVisitItem, b: IVisitItem) => number): void
	{
		WebView360GlobalSettings.debug && console.debug("[SearchEngine] sortVisits");

		if (this._visitsFiltered?.length > 0)
		{
			this._visitsFiltered = this._visitsFiltered.sort(sortFunction);
		}
		else
		{
			console.error("[SearchEngine] No data source to sort");
		}
		this._onDataChanged();
	}
	
	public getVisitsFiltered(): VisitArray 
	{
		WebView360GlobalSettings.debug && console.debug(`[SearchEngine] getVisitsFiltered datasource: ${this._visitsFilteredOnMap ? "_visitsFilteredOnMap" : "_visitsFiltered"}`);

		return this._visitsFilteredOnMap ? this._visitsFilteredOnMap : this._visitsFiltered;
	}

	private _initVisitsFiltered()
	{
		WebView360GlobalSettings.debug && console.debug("[SearchEngine] _initVisitsFiltered");

		while (this._visitsFiltered?.length)
		{
			this._visitsFiltered.pop();
		}
		this._dataController.getVisits()?.forEach(item => this._visitsFiltered.push(item));
		this._visitsFilteredOnMap = this._visitsFiltered;
		this._checkAndApplyLimit();
	}

	public static isSearchField(field: string): boolean
	{
		return field === "Nom" || 
		field === "Departement" || 
		field === "Ville" || 
		field === "Categorie" || 
		field === "Sous_cat" || 
		field === "Groupe" ||
		field === "Favoris" ||
		field === "Drone" || 
		field === "Lien_VE";
	}

	/**
	* This function compare the auto completion item with the search input to sort the datalist to optimize the result.
	* @param a
	* @param b
	* @returns {number}
	*/
	private _compareSearchSuggestion(a: IAutoCompletionItem, b: IAutoCompletionItem, value: string) 
	{
		const aPos = StringHelper.accentFold(a.value.toLowerCase()).indexOf(StringHelper.accentFold(value.toLowerCase()));
		const bPos = StringHelper.accentFold(b.value.toLowerCase()).indexOf(StringHelper.accentFold(value.toLowerCase()));
		if (aPos > bPos) 
		{
			return (1);
		}
		else if (aPos < bPos) 
		{
			return (-1);
		}
		else if (aPos === bPos) 
		{
			if ((a.field === "Categorie" && b.field === "Categorie") || (a.field === "Sous_cat" && b.field === "Sous_cat")) 
			{
				return (a.value.localeCompare(b.value));
			}
			else if ((a.field === "Categorie" && b.field !== "Categorie" && b.field !== "Sous_cat")
				|| (a.field === "Sous_cat" && b.field !== "Sous_cat" && b.field !== "Categorie")
				|| (a.field === "Categorie" && b.field === "Sous_cat")) 
			{
				return (-1);
			}
			else if ((b.field === "Categorie" && a.field !== "Categorie" && a.field !== "Sous_cat")
				|| (b.field === "Sous_cat" && a.field !== "Sous_cat" && a.field !== "Categorie")
				|| (b.field === "Categorie" && a.field === "Sous_cat")) 
			{
				return (1);
			}
			else 
			{
				return (a.value.localeCompare(b.value));
			}
		}
	}

	public getSearchFactory(): ISearchFactory
	{
		return this._factory;
	}


	private _refreshDynamicAutoComplete(factory: ISearchFactory, value: string)
	{

		if (value !== "") 
		{
			this._autoCompletionList.sort((a, b) => this._compareSearchSuggestion(a, b, value));
			this._autoCompletionList = this._autoCompletionList.splice(0, 5);
			this._autoCompletionList.forEach((item) =>
			{
				const picto: HTMLImageElement = document.createElement("IMG") as HTMLImageElement;
				picto.setAttribute("class", "picto_dyn_list");

				switch (item.field) 
				{
				case "Nom":
					picto.src = WebView360GlobalSettings.iconsPath + "shop.png";
					break;
				case "Departement":
					picto.src = WebView360GlobalSettings.iconsPath + "pin.png";
					break;
				case "Ville":
					picto.src = WebView360GlobalSettings.iconsPath + "pin.png";
					break;
				case "Categorie":
					if (item.value === "Artisanat") 
					{
						picto.src = WebView360GlobalSettings.pictoPath + "artisan.png";
					}
					else if (item.value === "Autres") 
					{
						picto.src = WebView360GlobalSettings.pictoPath + "autre.png";
					}
					else if (item.value === "Commerce") 
					{
						picto.src = WebView360GlobalSettings.pictoPath + "commerce.png";
					}
					else if (item.value === "Hébergement") 
					{
						picto.src = WebView360GlobalSettings.pictoPath + "hebergement.png";
					}
					else if (item.value === "Loisirs") 
					{
						picto.src = WebView360GlobalSettings.pictoPath + "loisirs.png";
					}
					else if (item.value === "Restauration") 
					{
						picto.src = WebView360GlobalSettings.pictoPath + "restauration.png";
					}
					else if (item.value === "Santé / Bien être") 
					{
						picto.src = WebView360GlobalSettings.pictoPath + "sante.png";
					}
					else 
					{
						picto.src = WebView360GlobalSettings.pictoPath + "autre.png";
					}
					break;
				case "Sous_cat":
					break;
				}
				const option = document.createElement("LI");
				option.onmousedown = () =>
				{
					if (item.value && item.field)
					{
						factory.dismissAutoCompletion();
						if (item.parentItem)
						{
							this.searchByValueField(factory, [{value: item.parentItem.value, field: item.parentItem.field}, {value: item.value, field: item.field}]);
						}
						else
						{
							this.searchByValueField(factory, [{value: item.value, field: item.field}]);
						}
					}
				};
				// option.appendChild(picto); /** 30/04/2020 DISABLE PICTOS */
				option.appendChild(document.createTextNode(item.field === "Ville" || item.field === "Departement" ? (`${item.value} - (${item.field})`) : item.value));
				factory.getDynamicAutoCompleteElement().appendChild(option);
			});
		}
	}
}