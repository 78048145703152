import { Loader } from "@googlemaps/js-api-loader";
import { IVisit, Plateform } from "core";
import { WebView360GlobalSettings } from "globalSettings";
import { DomHelper } from "helpers/domHelper";
import { DeferredObject } from "helpers/promiseHelper";

export interface IMapController
{
	init(mapElement: HTMLElement): Promise<void>
	initDom(leftCenterElem: HTMLElement): void
	initMarkerClusters(markers: google.maps.Marker[]): void
	addMarkers(markers: google.maps.Marker[]): void
	getMap(): google.maps.Map
	getInfoWindow(): google.maps.InfoWindow
	addOnMapMoveListener(onMapMove: () => void): void
	centerMap(marker: google.maps.Marker, openInfoWindow?: boolean): void
	createMarker(visit: IVisit, plateform: Plateform): google.maps.Marker
	fitMapToMarkers():void
	reinitMapOptions(): void
	isInInitialMapBounds(latLng: google.maps.LatLngLiteral) : boolean
	getMarkerPxPosition(latLng: google.maps.LatLng) : {x: number, y: number}
}

export class MapController implements IMapController
{
	private _map: google.maps.Map;
	private _infoWindow: google.maps.InfoWindow;
	private _centerLat: number;
	private _centerLng: number;
	private _defaultZoom: number;
	private _mapElement: HTMLElement;
	private _apiMapKey: string = "AIzaSyBuD0HYfr_SgBuG_Co95386av1OtQSKa3k";
	private _mapEvent: unknown;
	private _markerCluster: MarkerClusterer;
	private _onMapMove: () => void
	private _isFitToMapMarkers: boolean;
	private _initialMapOptions: google.maps.MapOptions;
	private _isFitToMapMarkersPromise: DeferredObject<void>;

	public constructor(centerLat: number, centerLng: number, defaultZoom: number)
	{
		this._centerLat = centerLat;
		this._centerLng = centerLng;
		this._defaultZoom = defaultZoom;
		this._isFitToMapMarkers = false;
		this._isFitToMapMarkersPromise = null;
		this._initialMapOptions = {
			center: { lat: this._centerLat, lng: this._centerLng },
			zoom: this._defaultZoom,
			minZoom: DomHelper.isMobileDevice() ? 0 : this._defaultZoom,
			maxZoom: DomHelper.isMobileDevice() ? 15 : 18,
			mapTypeControl: false,
			fullscreenControl: false,
			streetViewControl: false,
			gestureHandling: "greedy",
			restriction: {
				latLngBounds: {
					north: 52.0467,
					south: 40.7401,
					west: -16.9191,
					east: 20.5808,
				},
				strictBounds: false,
			}
		};
	}
	
	public reinitMapOptions(): void
	{
		this._map.setOptions(this._initialMapOptions);
	}

	public init(mapElement: HTMLElement): Promise<void>
	{
		WebView360GlobalSettings.debug && console.debug("[MapController] init");

		this._mapElement = mapElement;
		return new Loader
		({
			apiKey: this._apiMapKey
		}).load().then(() => this._initMap());
	}

	public initDom(leftCenterElem: HTMLElement): void
	{
		WebView360GlobalSettings.debug && console.debug("[MapController] initDom");
		this._map.controls[google.maps.ControlPosition.LEFT_CENTER].push(leftCenterElem);
	}

	private _initMap()
	{
		WebView360GlobalSettings.debug && console.debug("[MapController] _initMap");

		/**
		 * Initialisation of the Google Maps Map object. To set this object you need the Div element #mapElem, the center of the map
		 * with the position (latitude, longitude), a zoom index and some parameters to customize the map.
		 * @type {google.maps.Map}
		 */
		this._map = new google.maps.Map(this._mapElement, this._initialMapOptions);

		this._infoWindow = new google.maps.InfoWindow();
		this._mapEvent = google.maps.event;

		/**
		 * Customize the style of the map.
		 */
		this._map.setOptions({ styles: [{ featureType: "poi", stylers: [{ visibility: "off" }] }] });

		google.maps.event.addListener(this._map, "idle", () => 
		{
			WebView360GlobalSettings.debug && console.debug("[MapController][maps.event]: idle");
			
			this._markerCluster?.repaint();
			if (this._isFitToMapMarkersPromise)
			{
				this._isFitToMapMarkersPromise.resolve();
				this._isFitToMapMarkersPromise = null;
			}
			else
			{
				this._onMapMove && this._onMapMove();
			}
		});

		google.maps.event.trigger(this._map, "resize");
	}
	
	public fitMapToMarkers():void
	{
		if (this._markerCluster.getMarkers().length > 0)
		{
			WebView360GlobalSettings.debug && console.debug("[MapController]: fitMapToMarkers");
			this._isFitToMapMarkers = true;
			this._isFitToMapMarkersPromise = DeferredObject.create();
			this._isFitToMapMarkersPromise.promise.then(() => 
			{
				if (this._markerCluster.getMarkers().filter((marker) => this._map.getBounds().contains(marker.getPosition())).length !== this._markerCluster.getMarkers().length && this._map.getZoom() !== this._defaultZoom)
				{
					this.fitMapToMarkers();
				}
				else
				{
						
					this._isFitToMapMarkers = false;
					this._isFitToMapMarkersPromise;
				}
				WebView360GlobalSettings.debug && console.debug("[MapController]: fitMapToMarkers -> resolve");
			});
			this._markerCluster && this._markerCluster.fitMapToMarkers();
		}
	}

	public initMarkerClusters(markers: google.maps.Marker[]): void 
	{
		WebView360GlobalSettings.debug && console.debug("[MapController] initMarkerClusters");

		if (this._markerCluster)
		{
			this._markerCluster.clearMarkers();
			this._markerCluster.addMarkers(markers);
		}
		else
		{
			this._markerCluster = new MarkerClusterer(this._map, markers, { imagePath: WebView360GlobalSettings.clusterPath }); // TODO improve memory not recreate
			this._markerCluster.setMaxZoom(13);
			if (DomHelper.isMobileDevice())
			{
				this._markerCluster.setGridSize(70);
			}
			else
			{
				this._markerCluster.setGridSize(80);
			}
			this._markerCluster.setStyles([
				{
					url: WebView360GlobalSettings.clusterPath + "1.png",
					width: 58,
					height: 58,
					anchorText: [21, 0],
					textColor: "white"
				},
				{
					url: WebView360GlobalSettings.clusterPath + "2.png",
					width: 72,
					height: 72,
					anchorText: [28, 0],
					textColor: "white"
				},
				{
					url: WebView360GlobalSettings.clusterPath + "3.png",
					width: 94,
					height: 94,
					anchorText: [40, 0],
					textColor: "white"
				}
			]);
		}

	}

	public addMarkers(markers: google.maps.Marker[]): void
	{
		if (this._markerCluster)
		{
			this._markerCluster.addMarkers(markers);
		}
	}

	public addOnMapMoveListener(onMapMove: () => void): void 
	{
		WebView360GlobalSettings.debug && console.debug("[MapController] addOnMapMoveListener");

		this._onMapMove = onMapMove;
	}
	
	public getMap(): google.maps.Map
	{
		return this._map;
	}

	public getInfoWindow(): google.maps.InfoWindow
	{
		return this._infoWindow;
	}

	public centerMap(marker: google.maps.Marker, openInfoWindow: boolean = true): void
	{
		WebView360GlobalSettings.debug && console.debug("[MapController] centerMap");

		this._map.setZoom(14);
		this._map.setCenter(marker.getPosition());
		openInfoWindow && google.maps.event.trigger(marker, "click"/**"mouseover"*/);
	}

	public createMarker(visit: IVisit, plateform: Plateform): google.maps.Marker
	{
		let icon;

		if (plateform === "PF")
		{
			return null;
		}
		switch (visit.Categorie)
		{
		case ("Artisanat"):
		{
			icon = `${WebView360GlobalSettings.pictoPath}artisan.png`;
			break;
		}
		case ("Autres"):
		{
			icon = `${WebView360GlobalSettings.pictoPath}autre.png`;
			break;
		}
		case ("Commerce"):
		{
			icon = `${WebView360GlobalSettings.pictoPath}commerce.png`;
			break;
		}
		case ("Hébergement"):
		{
			icon = `${WebView360GlobalSettings.pictoPath}hebergement.png`;
			break;
		}
		case ("Restauration"):
		{
			icon = `${WebView360GlobalSettings.pictoPath}loisirs.png`;
			break;
		}
		case ("Santé / Bien être"):
		{
			icon = `${WebView360GlobalSettings.pictoPath}sante.png`;
			break;
		}
		default:
		{
			icon = `${WebView360GlobalSettings.pictoPath}autre.png`;
		}
		}

		const marker = new google.maps.Marker(
			{
				position: { lat: visit.GPS_Lat, lng: visit.GPS_Lng },
				title: visit.Nom,
				icon:
				{
					url: icon,
					scaledSize: new google.maps.Size(40, 40),
					origin: new google.maps.Point(0, 0)
				},
				anchorPoint: new google.maps.Point(0, -40)
			}
		);

		return marker;
	}

	public isInInitialMapBounds(latLng: google.maps.LatLngLiteral) : boolean
	{
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		return latLng.lat < (this._initialMapOptions.restriction.latLngBounds as any).north && latLng.lat > (this._initialMapOptions.restriction.latLngBounds as any).south && latLng.lng > (this._initialMapOptions.restriction.latLngBounds as any).west && latLng.lng < (this._initialMapOptions.restriction.latLngBounds as any).east;
	}

	public getMarkerPxPosition(latLng: google.maps.LatLng) : {x: number, y: number}
	{
		const mapWidthPx = this._mapElement.clientWidth;
		const mapHeightPx = this._mapElement.clientHeight;
		const mapWidthPt = this._map.getProjection().fromLatLngToPoint(this._map.getBounds().getNorthEast()).x - this._map.getProjection().fromLatLngToPoint(this._map.getBounds().getSouthWest()).x;
		const mapHeightPt = this._map.getProjection().fromLatLngToPoint(this._map.getBounds().getSouthWest()).y - this._map.getProjection().fromLatLngToPoint(this._map.getBounds().getNorthEast()).y;
		
		const markerX = (this._map.getProjection().fromLatLngToPoint(latLng).x - this._map.getProjection().fromLatLngToPoint(this._map.getBounds().getSouthWest()).x) * mapWidthPx / mapWidthPt;
		const markerY = (this._map.getProjection().fromLatLngToPoint(latLng).y - this._map.getProjection().fromLatLngToPoint(this._map.getBounds().getNorthEast()).y) * mapHeightPx / mapHeightPt;
		return {x: markerX, y: markerY};
	}
}