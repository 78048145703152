/* eslint-disable @typescript-eslint/no-explicit-any */
import { WVDataBase } from "apiController";

export class URLHelper
{
	public static getUrlVars() : any 
	{
		const vars = {};
		window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) 
		{
			vars[key] = value;
			return (value);
		});
		return vars;
	}

	public static getUrlParam(parameter: string, defaultvalue: unknown) : any
	{
		let urlparameter = defaultvalue;
		if (window.location.href.indexOf(parameter + "=") > -1) 
		{
			urlparameter = URLHelper.getUrlVars()[parameter];
		}
		return urlparameter && JSON.parse(urlparameter as string);
	}

	public static getRequestUrlDBPrefix(db: WVDataBase) : string 
	{
		return db ? `/${db.toString()}/` : "/";
	}
}