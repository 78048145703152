import { ISearchEngine } from "./searchEngine";
import { HTMLElementFactory } from "./helpers/htmlElementFactory";
import { IWebView360Component } from "./interfaces/IWebView360Component";
import { WebView360GlobalSettings } from "./globalSettings";
import { DrawerState, IDrawer } from "./drawer";
import { IMapController } from "mapController";
import { IWebView360Config } from "core";
import { ISearchFactory } from "searchFactory";
import { IAPIController } from "apiController";
import { SearchHelper } from "helpers/searchHelper";
import { IVisitsDrawer } from "visitsDrawer";

export interface IPanomap extends IWebView360Component {
	getDrawer(): IVisitsDrawer
	getMapController(): IMapController
}

export class Panomap implements IPanomap 
{
	private _searchFactory: ISearchFactory;
	private _drawer: IVisitsDrawer;
	private _mapController: IMapController;

	private _config: IWebView360Config;

	private _htmlElement: HTMLElement;
	private _mapElement: HTMLElement;

	public constructor(searchEngine: ISearchEngine, drawer: IVisitsDrawer, mapController: IMapController, config: IWebView360Config)
	{
		this._searchFactory = searchEngine.createSearchFactory("panomap");
		this._drawer = drawer;
		this._mapController = mapController;
		this._config = config;
	}

	public init(apiController: IAPIController): Promise<void> 
	{
		WebView360GlobalSettings.debug && console.debug("[Panomap] init");

		this._searchFactory.initDom();
		this.initDom();
		return Promise.all([this._config.plateform !== "PF" ? this._initMap() : Promise.resolve(), this._searchFactory.init(apiController, SearchHelper.configToSearchCriteria(this._config))])
			.then(() => Promise.resolve());
	}

	private _initMap(): Promise<void> 
	{
		WebView360GlobalSettings.debug && console.debug("[Panomap] _initMap");

		return this._mapController.init(this._mapElement)
			.then(() => 
			{
				WebView360GlobalSettings.debug && console.debug("[Panomap] _initMap -> map ready");
				this._drawer.setOnMoveListenerVisitDrawer((fromState: DrawerState) =>
				{
					if (fromState !== "extended")
					{
						this._mapController.getMap().panBy(-200, 0);
					}
				},() =>
				{
					this._mapController.getMap().panBy(+200, 0);
				}, null);
			});
	}

	public initDom(): void 
	{
		WebView360GlobalSettings.debug && console.debug("[Panomap] initDom");

		this._initDomElements();
		this._drawer.init(this._config.showSearchZone ? this._searchFactory.render() : null, this._config.customText);
	}

	private _initDomElements(): void 
	{
		this._htmlElement = HTMLElementFactory.createHTMLDivElement("pano_map");
		this._mapElement = HTMLElementFactory.createHTMLDivElement("map");
	}

	public render(): void 
	{
		WebView360GlobalSettings.debug && console.debug("[Panomap] render");

		this._htmlElement.appendChild(this._mapElement);
		this._htmlElement.appendChild(this._drawer.render());
		this.getVisitsContainer().parentNode.insertBefore(HTMLElementFactory.createHTMLLoaderElement(), this.getVisitsContainer()); // append to panos_container
	}

	public setFullScreen(toggle: boolean): void 
	{
		WebView360GlobalSettings.debug && console.debug(`[Panomap] setFullScreen: ${toggle}`);

		if (toggle) 
		{
			this._htmlElement.style.top = "0";
			this._htmlElement.style.position = "fixed";
			this._htmlElement.style.height = "100%";
			this._htmlElement.style.zIndex = "3";
			this._mapElement.style.height = "100%";
		}
		else 
		{
			// TODO: implement not fullscreenmode
		}
	}

	public getHTMLElement(): HTMLElement 
	{
		return this._htmlElement;
	}

	public getSearchFactory(): ISearchFactory
	{
		return this._searchFactory;
	}

	public getVisitsContainer(): HTMLElement 
	{
		return this.getDrawer().getScrollableElement();
	}

	public getMapElement(): HTMLElement 
	{
		return this._mapElement;
	}

	public getMapController(): IMapController 
	{
		return this._mapController;
	}

	public getDrawer(): IVisitsDrawer
	{
		return this._drawer;
	}
}