import { APICacheController, IAPICacheController } from "apiCacheController";
import { WebView360GlobalSettings } from "globalSettings";
import { URLHelper } from "helpers/urlHelper";

export type WVDataBase = "dr" | "ve" | "bu" | "";

export interface IAPIController
{
	getCategories(): Promise<string[]>
	getSubCategories(category: string): Promise<string[]>
	getDepartments(): Promise<string[]>
	getCities(department: string): Promise<string[]>
	getVisit(visitId: string): Promise<string>
	getVisits(): Promise<Object[]>
	getVisitsStream(onEachResult: (data: Object[]) => void): Promise<Object[]>
	getTemplate(template: string): Promise<Object>;
	setDB(db: WVDataBase);
}

export class APIController implements IAPIController
{
	private _apiRawUrl: string = "https://api.web-visite.com/api"; 
	private _apiUrl: string = this._apiRawUrl; 
	private _db: WVDataBase;
	private _nbPerPage: number = 700;
	private _cacheController: IAPICacheController;
	private _getTemplatePromise: Promise<Object>;
	private _getCategoriesPromise: Promise<string[]>;
	private _getActivitiesPromise: {};
	private _getDepartmentsPromise: Promise<string[]>;
	private _getCitiesPromise: {};

	public constructor()
	{
		this._cacheController = new APICacheController();
		this._getActivitiesPromise = {};
		this._getCitiesPromise = {};
	}
	
	public setDB(db?: WVDataBase): void 
	{
		this._db = db;
		this._apiUrl += URLHelper.getRequestUrlDBPrefix(db);
	}
	
	public getTemplate(template: string): Promise<Object> 
	{
		this._getTemplatePromise = fetch(this._apiRawUrl + "/ctemplate/" + encodeURI(template))
			.then((response) => 
			{
				return response.json();
			})
			.then((json) =>
			{
				WebView360GlobalSettings.debug && console.debug("[APIController] getTemplate -> resolved");
				const template = JSON.parse(json)[0];
				Object.keys(template).forEach((key) => 
				{
					if (template[key] === "true")
					{
						template[key] = true;
					}
					if (template[key] === "false")
					{
						template[key] = false;
					}
				});
				this._getTemplatePromise = null;
				return Promise.resolve(template);
			});
		return this._getTemplatePromise;
	}

	public getVisit(visitId: string): Promise<string>
	{
		const reqPromise = fetch(this._apiUrl + `visit/${visitId}`)
			.then((response) => 
			{
				return response.json();
			})
			.then((json: string) =>
			{
				WebView360GlobalSettings.debug && console.debug(`[APIController] getVisit(${visitId}) -> resolved`);
				return Promise.resolve(json);
			});
		return reqPromise;
	}

	public getCategories(): Promise<string[]>
	{
		if (this._cacheController.hasCategoriesCached())
		{
			return Promise.resolve(this._cacheController.getCategories());
		}
		if (this._getCategoriesPromise)
		{
			return this._getCategoriesPromise;
		}
		WebView360GlobalSettings.debug && console.debug("[APIController] getCategories");
		this._getCategoriesPromise = fetch(this._apiUrl + "category/categories")
			.then((response) => 
			{
				return response.json();
			})
			.then((json) =>
			{
				WebView360GlobalSettings.debug && console.debug("[APIController] getCategories -> resolved");
				const categories = (JSON.parse(json) as []).map(elem => elem["Categorie"]);
				this._cacheController.setCategories(categories);
				this._getCategoriesPromise = null;
				return Promise.resolve(categories);
			});
		return this._getCategoriesPromise;
	}

	public getSubCategories(category: string): Promise<string[]>
	{
		if (this._cacheController.hasActivitiesCached(category))
		{
			return Promise.resolve(this._cacheController.getActivities(category));
		}
		if (Object.keys(this._getActivitiesPromise).includes(category) && this._getActivitiesPromise[category])
		{
			return this._getActivitiesPromise[category];
		}
		WebView360GlobalSettings.debug && console.debug("[APIController] getSubCategories");
		this._getActivitiesPromise[category] = fetch(this._apiUrl + "category/sub_categories/" + encodeURIComponent(category.replace("/", "#|#")))
			.then((response) => 
			{
				return response.json();
			})
			.then((json) =>
			{
				WebView360GlobalSettings.debug && console.debug("[APIController] getSubCategories -> resolved");
				const activities = (JSON.parse(json) as []).map(elem => elem["Sous_cat"]);
				this._cacheController.setActivities(activities, category);
				delete this._getActivitiesPromise[category];
				return Promise.resolve(activities);
			});
		return this._getActivitiesPromise[category];
	}
	
	public getDepartments(): Promise<string[]>
	{
		if (this._cacheController.hasDepartmentsCached())
		{
			return Promise.resolve(this._cacheController.getDepartments());
		}
		if (this._getDepartmentsPromise)
		{
			return this._getDepartmentsPromise;
		}
		WebView360GlobalSettings.debug && console.debug("[APIController] getDepartments");
		this._getDepartmentsPromise = fetch(this._apiUrl + "location/departments")
			.then((response) => 
			{
				return response.json();
			})
			.then((json) =>
			{
				WebView360GlobalSettings.debug && console.debug("[APIController] getDepartments -> resolved");
				const departments = (JSON.parse(json) as []).map(elem => elem["Departement"]);
				this._cacheController.setDepartments(departments);
				this._getDepartmentsPromise = null;
				return Promise.resolve(departments);
			});
		return this._getDepartmentsPromise;
	}
	
	public getCities(department: string): Promise<string[]>
	{
		if (this._cacheController.hasCitiesCached(department))
		{
			return Promise.resolve(this._cacheController.getCities(department));
		}
		if (Object.keys(this._getCitiesPromise).includes(department) && this._getCitiesPromise[department])
		{
			return this._getCitiesPromise[department];
		}
		WebView360GlobalSettings.debug && console.debug("[APIController] getCities");
		this._getCitiesPromise[department] = fetch(this._apiUrl + "location/cities/" + encodeURIComponent(department))
			.then((response) => 
			{
				return response.json();
			})
			.then((json) =>
			{
				WebView360GlobalSettings.debug && console.debug("[APIController] getCities -> resolved");
				const cities = (JSON.parse(json) as []).map(elem => elem["Ville"]);
				this._cacheController.setCities(cities, department);
				delete this._getCitiesPromise[department];
				return Promise.resolve(cities);
			});
		return this._getCitiesPromise[department];
	}

	public getVisits(): Promise<Object[]>
	{
		return this._getVisistsParallelized();
	}

	public getVisitsStream(onEachResult: (data: Object[]) => void): Promise<Object[]>
	{
		return this._getVisistsParallelized(onEachResult);
	}

	private _getVisistsParallelized(onEachResult?: (data: Object[]) => void): Promise<Object[]>
	{
		WebView360GlobalSettings.debug && console.debug("[APIController] getVisits");
		const promisesArray: Promise<string>[] = [];
		return fetch(this._apiUrl + "visitsexp/count")
			.then((response) => 
			{
				return response.json();
			})
			.then((json) =>
			{
				const visitsCount = JSON.parse(json);
				const url = new URL(this._apiUrl + "visitsexp");

				new URLSearchParams();

				for (let i = 0; i < visitsCount / this._nbPerPage; i++)
				{
					const params = 
					[
						["page", (i+1).toString()],
						["nb_per_page", this._nbPerPage.toString()]
					];
					url.search = new URLSearchParams(params).toString();
					WebView360GlobalSettings.debug && console.time("req page"+(i+1));
					const promise = fetch(url.toString())
						.then((response) => response.json());
					
					if (onEachResult)
					{
						promise
							.then((json: string) =>
							{
								WebView360GlobalSettings.debug && console.timeEnd("req page"+(i+1));
								onEachResult(JSON.parse(json));
								return json;
							});
					}
					promisesArray.push(promise);
				}
			})
			.then(() => Promise.all(promisesArray))
			.then((resultArray: string[]) =>
			{
				let visits: Object[] = [];
				resultArray.forEach((items: string) =>
				{
					visits = visits.concat(JSON.parse(items));
				});
				return Promise.resolve(visits);
			});
	}

	private _getVisistsBulk(): Promise<Object[]>
	{
		WebView360GlobalSettings.debug && console.debug("[APIController] getVisits");
		return fetch(this._apiUrl + "visits")
			.then((response) => 
			{
				return response.json();
			})
			.then((json) =>
			{
				WebView360GlobalSettings.debug && console.debug("[APIController] getVisits -> resolved");
				return Promise.resolve(JSON.parse(json));
			});
	}


}