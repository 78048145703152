import { IVisit, IVisiteDetailled } from "core";
import { Drawer } from "drawer";
import { HTMLElementFactory } from "helpers/htmlElementFactory";

export interface IVisitDetailDrawer extends Drawer
{
	visit: IVisiteDetailled;
	initDrawerDOM(visit: IVisit): void
}

interface SectionConfig {
	visitLink: string,
	visitLinkLabel: string
	iFrameUrl: string,
	QRCodeLink: string,
	statsLink: string
}

export class VisitDetailDrawer extends Drawer 
{
	public visit: IVisiteDetailled;

	public buildSection(config: SectionConfig, id: string, title: string): HTMLElement
	{
		const topContainer = HTMLElementFactory.createHTMLDivElement(`visit-visu${id}-topcontainer`, { class: "detail-section top-section" });
		const botContainer = HTMLElementFactory.createHTMLDivElement(`visit-visu${id}-botcontainer`, { class: "detail-section bot-section" });
		const dataContainer = HTMLElementFactory.createHTMLDivElement(`visit-visu${id}-data-container`, { class: "detail-data-section detail-visit" });

		const sectionElem = HTMLElementFactory.createHTMLDivElement(`visit-visu${id}-container`, { class: "detail-section visit-section" });

		dataContainer.appendChild(HTMLElementFactory.createHTMLSpanElement(null, null, null, { class: "detail-visit-section-title" }, null, title));

		const visitLink = HTMLElementFactory.createHTMLDivElement("visit-link", { class: "detail-item grey" }, undefined, undefined, undefined, undefined, undefined, null);
		const visitLinkA = document.createElement("a");
		visitLinkA.href = config.visitLink;
		visitLinkA.target = "_blank";
		visitLinkA.appendChild(document.createTextNode(config.visitLinkLabel));
		visitLink.appendChild(visitLinkA);

		dataContainer.appendChild(visitLink);

		const stats = HTMLElementFactory.createHTMLDivElement("stats-link", { class: "detail-item" }, undefined, undefined, undefined, undefined, undefined, null);
		const statsA = document.createElement("a");
		statsA.href = config.statsLink;
		statsA.target = "_blank";
		statsA.appendChild(document.createTextNode("Voir les statistiques"));
		stats.appendChild(statsA);

		if (config.statsLink)
		{
			dataContainer.appendChild(stats);
		}

		const iFrame = HTMLElementFactory.createHTMLIFrameElement(config.iFrameUrl, "auto", "auto", "0", { border: "0" });
		const iFrameElem = HTMLElementFactory.createHTMLDivElement("iframe", { class: "detail-item right-item" }, undefined, undefined, undefined, undefined, undefined);
		iFrameElem.appendChild(iFrame);

		const qrcodedl = HTMLElementFactory.createHTMLDivElement("qrcodedl-link", { class: "detail-item" }, undefined, undefined, undefined, undefined, undefined, null, "QR CODE");
		const qrcodedlA = document.createElement("a");
		qrcodedlA.href = config.QRCodeLink;
		qrcodedlA.target = "_blank";
		qrcodedlA.download = "true";
		qrcodedlA.appendChild(document.createTextNode("Télécharger"));
		qrcodedl.appendChild(qrcodedlA);

		const qrCode = HTMLElementFactory.createHTMLDivElement("qr-code", { class: "detail-item" }, undefined, undefined, undefined, undefined, undefined, null);
		const qrCodeImg = HTMLElementFactory.createHTMLImgElement("qr-code-img", config.QRCodeLink, {class: "qr-code"});
		qrCode.appendChild(qrCodeImg);
		qrCode.appendChild(qrcodedl);

		const needhelp = HTMLElementFactory.createHTMLDivElement("needhelp-link", { class: "detail-item" }, undefined, undefined, undefined, undefined, undefined, null);
		const needhelpA = document.createElement("a");
		needhelpA.href = "https://www.web-visite.com/tutoriels";
		needhelpA.target = "_blank";
		needhelpA.appendChild(document.createTextNode("Consulter les tutoriels"));
		needhelp.appendChild(document.createTextNode("Besoin d'aide ?"));
		needhelp.appendChild(needhelpA);

		const visitUrlInputContainer = HTMLElementFactory.createHTMLDivElement("visit-link", { class: "detail-item" }, undefined, undefined, undefined, undefined, undefined, null, "Lien de la visite virtuelle");
		const visitUrlInput = HTMLElementFactory.createHTMLTextareaElement(null, "visit-url", { rows: 1, cols: 100, class: "detail-url-input" }, undefined, undefined, undefined, undefined);
		visitUrlInput.value = config.visitLink;
		visitUrlInput.disabled = true;
		visitUrlInputContainer.appendChild(visitUrlInput);

		const iframeInputContainer = HTMLElementFactory.createHTMLDivElement("iframe-visit-code", { class: "detail-item" }, undefined, undefined, undefined, undefined, undefined, null, "Code IFrame");
		const iframeInput = HTMLElementFactory.createHTMLTextareaElement(null, "iframe-code", { rows: 5, cols: 100, class: "detail-code-input" }, undefined, undefined, undefined, undefined);
		iframeInput.value = iFrame.outerHTML;
		iframeInput.disabled = true;
		iframeInputContainer.appendChild(iframeInput);

		botContainer.appendChild(visitUrlInputContainer);
		botContainer.appendChild(iframeInputContainer);

		topContainer.appendChild(iFrameElem);
		topContainer.appendChild(dataContainer);

		dataContainer.appendChild(qrCode);
		dataContainer.appendChild(needhelp);

		sectionElem.appendChild(topContainer);
		sectionElem.appendChild(botContainer);

		return sectionElem;
	}

	public initDrawerDOM(visit: IVisiteDetailled): void
	{
		this.visit = visit;
		const visitDetailContainer = HTMLElementFactory.createHTMLDivElement("visit-detail-container");
		const headerContainer = HTMLElementFactory.createHTMLDivElement("visit-header-container", { class: "detail-section" });
		const nameAddressContainer = HTMLElementFactory.createHTMLDivElement("visit-nameaddress-container", { class: "detail-data-section" });
		const contactInfoContainer = HTMLElementFactory.createHTMLDivElement("visit-contactinfo-container", { class: "detail-data-section" });
		const contactUsContainer = HTMLElementFactory.createHTMLDivElement("visit-contactus-container", { class: "detail-data-section" });

		headerContainer.appendChild(nameAddressContainer);
		headerContainer.appendChild(contactInfoContainer);
		headerContainer.appendChild(contactUsContainer);

		visitDetailContainer.appendChild(headerContainer);

		nameAddressContainer.appendChild(HTMLElementFactory.createHTMLDivElement("name", { class: "detail-item" }, undefined, undefined, undefined, undefined, undefined, visit.Nom));
		//nameAddressContainer.appendChild(HTMLElementFactory.createHTMLDivElement("cat", { class: "detail-item" }, undefined, undefined, undefined, undefined, undefined, visit.Sous_cat));
		nameAddressContainer.appendChild(HTMLElementFactory.createHTMLDivElement("address", { class: "detail-item" }, undefined, undefined, undefined, undefined, undefined, visit.Adresse.replace(",", "\n"), "Adresse"));
		nameAddressContainer.appendChild(HTMLElementFactory.createHTMLDivElement("dep", { class: "detail-item" }, undefined, undefined, undefined, undefined, undefined, visit.Departement, "Département"));

		// const mapElem = document.getElementById("map");
		// mapElem.style.position = "initial";
		// mapElem.classList.add("right-item");
		// (mapElem.children[0] as HTMLElement).style.position = "relative";
		//headerContainer.appendChild(mapElem);

		const gPage = HTMLElementFactory.createHTMLDivElement("g-page", { class: "detail-item" }, undefined, undefined, undefined, undefined, undefined, null);
		const gPageA = document.createElement("a");
		gPageA.href = visit.Page_GMap;
		gPageA.target = "_blank";
		gPageA.appendChild(document.createTextNode("Voir la fiche d'établissement Google"));
		gPage.appendChild(gPageA);

		nameAddressContainer.appendChild(gPage);

		const avisGoogle = HTMLElementFactory.createHTMLDivElement("g-avis", { class: "detail-item" }, undefined, undefined, undefined, undefined, undefined, null);
		const avisGoogleA = document.createElement("a");
		avisGoogleA.href = visit.Lien_avis_Google;
		avisGoogleA.target = "_blank";
		avisGoogleA.appendChild(document.createTextNode("Voir les avis Google"));
		avisGoogle.appendChild(avisGoogleA);

		nameAddressContainer.appendChild(avisGoogle);

		contactUsContainer.appendChild(HTMLElementFactory.createHTMLDivElement("date-publi", { class: "detail-item" }, undefined, undefined, undefined, undefined, undefined, (new Date(visit.Date_Publie)).toLocaleDateString(), "Date de publication"));
		
		const askUpdate = HTMLElementFactory.createHTMLDivElement("ask-update", { class: "detail-item blue" }, undefined, undefined, undefined, undefined, undefined, null);
		const askUpdateA = document.createElement("a");
		askUpdateA.href = `https://zfrmz.com/gezqQ8RebWXySxEeNzRT/?nom=${visit.Nom}&ville=${visit.Ville}&id=${visit.Id}`;
		askUpdateA.target = "_blank";
		askUpdateA.appendChild(document.createTextNode("Demander une mise à jour"));
		askUpdate.appendChild(askUpdateA);

		contactUsContainer.appendChild(askUpdate);

		const mailUs = HTMLElementFactory.createHTMLDivElement("g-page", { class: "detail-item" }, undefined, undefined, undefined, undefined, undefined, null);
		const mailUsA = document.createElement("a");
		mailUsA.href = "mailto:support@web-visite.com";
		mailUsA.target = "_blank";
		mailUsA.appendChild(document.createTextNode("Contactez-nous"));
		mailUs.appendChild(mailUsA);

		contactUsContainer.appendChild(mailUs);
		
		contactInfoContainer.appendChild(HTMLElementFactory.createHTMLDivElement("info-section-title", { class: "section-title" }, undefined, undefined, undefined, undefined, undefined, "Informations"));
		contactInfoContainer.appendChild(HTMLElementFactory.createHTMLDivElement("contact", { class: "detail-item" }, undefined, undefined, undefined, undefined, undefined, visit.Client_Name, "Contact"));
		contactInfoContainer.appendChild(HTMLElementFactory.createHTMLDivElement("tel", { class: "detail-item" }, undefined, undefined, undefined, undefined, undefined, visit.Phone_Nb, "Téléphone"));
		contactInfoContainer.appendChild(HTMLElementFactory.createHTMLDivElement("mail", { class: "detail-item" }, undefined, undefined, undefined, undefined, undefined, visit.Email, "Mail"));

		visitDetailContainer.appendChild(this.buildSection({
			visitLink: visit.Lien_VVP,
			iFrameUrl: visit.iFrame,
			QRCodeLink: visit.Lien_QRCode_VVP,
			visitLinkLabel: "Voir sur Google Maps",
			statsLink: visit.Stats_GSV
		}, "google", "Street View | Entreprise"));

		if (visit.Drone_Iframe_VVP && visit.Drone_Lien_VVP)
		{
			visitDetailContainer.appendChild(this.buildSection({
				visitLink: visit.Drone_Lien_VVP,
				iFrameUrl: visit.Drone_Iframe_VVP,
				QRCodeLink: `https://chart.googleapis.com/chart?cht=qr&chs=350x350&chl=${visit.Drone_Lien_VVP}`,
				visitLinkLabel: "Voir sur Google Maps",
				statsLink: visit.Stats_GSV
			}, "drone", "Street View | Drone"));
		}

		if (visit.Lien_VE)
		{
			visitDetailContainer.appendChild(this.buildSection({
				visitLink: visit.Lien_VE,
				iFrameUrl: visit.Lien_VE,
				QRCodeLink: `https://chart.googleapis.com/chart?cht=qr&chs=350x350&chl=${visit.Lien_VE}`,
				visitLinkLabel: "Voir la Visite Virtuelle",
				statsLink: visit.Stats_VE
			}, "ve", "Webvisite Personalisée"));
		}

		this.getScrollableElement().appendChild(visitDetailContainer);
	}
}