import { WebView360GlobalSettings } from "globalSettings";
import { IVisitsRenderController } from "visitsRenderController";

export interface IScrollDisplayerController
{
	init();
}

export class ScrollDisplayerController implements IScrollDisplayerController
{
	private _visitsRenderController: IVisitsRenderController;
	private _container: HTMLElement;
	private _linesToAdd: number = 3;
	private _triggerThreshold: number = 0.8;
	private _debugScroll: boolean = false;

	public constructor(visitsRenderController: IVisitsRenderController, container: HTMLElement)
	{
		this._visitsRenderController = visitsRenderController;
		this._container = container;
	}
	public init() : void
	{
		this._container.onscroll = () => this._scroll();
	}

	private _scroll(): void
	{
		const visitHeight = (this._container.querySelector(".wv_item") as HTMLElement).offsetWidth;
		const visitAdded: number = parseInt((this._container.offsetWidth / visitHeight).toString(), 10) * this._linesToAdd;

		WebView360GlobalSettings.debug && this._debugScroll && console.debug(`[ScrollDisplayerController] scroll - scrollTop:${this._container.scrollTop},
			triggerThreshold:${(this._container.scrollHeight - this._container.offsetHeight) * this._triggerThreshold})
			visitAdded:${visitAdded}`
		);
		
		if (this._container.scrollTop > (this._container.scrollHeight - this._container.offsetHeight) * this._triggerThreshold)
		{
			this._visitsRenderController.showVisits(this._visitsRenderController.getVisistsDisplayed(), visitAdded);
		}
	}
}