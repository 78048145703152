export class StringHelper
{
	/**
	* This function is used to replace accent letter by simple letter. To optimize the filtering.
	* @param inStr
	* @returns {string | void}
	*/
	public static accentFold(inStr: string): string
	{
		return inStr.replace(/([àáâãäå])|([ç])|([èéêë])|([ìíîï])|([ñ])|([òóôõöø])|([ß])|([ùúûü])|([ÿ])|([æ])/g, function (str, a, c, e, i, n, o, s, u, y, ae) 
		{
			if (a) return "a"; else if (c) return "c"; else if (e) return "e"; else if (i) return "i"; else if (n) return "n"; else if (o) return "o"; else if (s) return "s"; else if (u) return "u"; else if (y) return "y"; else if (ae) return "ae"; 
		});
	}
}