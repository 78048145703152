import { IVisit, IWebView360Config } from "core";
import { IVisitItem } from "dataController";
import { WebView360GlobalSettings } from "globalSettings";
import { DomHelper } from "helpers/domHelper";
import { HTMLElementFactory } from "helpers/htmlElementFactory";
import { IPanomap } from "panomap";
import { ISearchEngine } from "searchEngine";

export type VisitItemType = "PM" | "IW";

export interface IVisitItemDOM
{
	item: HTMLElement;
	itemRoot: HTMLElement;
	itemImg: HTMLElement;
	itemAttributesRightContainer: HTMLElement;
	itemAttributesLeftContainer: HTMLElement;
	veIcon: HTMLImageElement;
	droneIcon: HTMLImageElement;
	favIcon: HTMLImageElement;
	newIcon: HTMLImageElement;
	visitIcon: HTMLImageElement;
	droneChoice: HTMLElement;
	indoorChoice: HTMLElement;
	captionContainer: HTMLElement;
	caption: HTMLElement;
	visitName: HTMLHeadingElement;
	underCaption: HTMLElement;
	visitSubCategory: HTMLElement;
	visitDepartment: HTMLElement;
	visitCity: HTMLElement;
	sideButtonsBar: HTMLElement;
	downloadControlAction: HTMLAnchorElement;
	reportControlAction: HTMLAnchorElement;
	gsvControlAction: HTMLAnchorElement;
	overlayControlAction: HTMLAnchorElement;
	goToClientControlAction: HTMLAnchorElement;
	locateAction: HTMLImageElement;
	seeOnGMapAction: HTMLAnchorElement;
	controlButtonsContainer: HTMLElement;
	controlLeftButtonsContainer: HTMLElement;
	controlRightButtonsContainer: HTMLElement;
}

export interface IVisitsRenderController
{
	showVisits(nbStart?: number, nbPano?: number, isStream?: boolean): void
	buildVisitItem(visit: IVisitItem) : HTMLElement
	setIFrameContainer(iframeContainer: HTMLElement): void
	getVisistsDisplayed(): number
	renderVisitsByMapBouding(): void
	registerHandler(): void
}

export class VisitsRenderController implements IVisitsRenderController
{
	private _panomap: IPanomap;
	private _noDataElement: HTMLElement;
	private _searchEngine: ISearchEngine;
	private _config: IWebView360Config;
	private _iframeItem: HTMLElement;
	private _visitsDisplayed: number;
	private _nbPanoByDefault: number = 50;


	public constructor(searchEngine: ISearchEngine, config: IWebView360Config, panomap: IPanomap)
	{
		this._noDataElement = HTMLElementFactory.createHTMLHeadingElement(
			2, 
			"no_data",
			"Oups... Il n'y a pas encore de visites virtuelles dans cette zone...");

		this._searchEngine = searchEngine;
		this._config = config;
		this._panomap = panomap;
	}

	public registerHandler(): void
	{
		WebView360GlobalSettings.debug && console.debug("[VisitsRenderController] registerHandler");

		this._panomap.getMapController() && this._panomap.getMapController().addOnMapMoveListener(() =>
		{
			this.renderVisitsByMapBouding();
		});
	}

	public buildVisitItem(visit: IVisitItem) : HTMLElement
	{
		const visitItemDOM = {} as IVisitItemDOM;
		this._buildVisitItemDOM(visitItemDOM, visit);
		this._buildVisitItemLogic(visitItemDOM, visit);

		return visitItemDOM.itemRoot;
	}

	public showVisits(nbStart: number = 0, nbPano: number = this._nbPanoByDefault, isStream: boolean = false): void 
	{
		WebView360GlobalSettings.debug && console.debug(`[VisitsRenderController] showVisits(${nbStart}, ${nbPano})`);

		let i = nbStart;
		const visitsFilteredLength = this._searchEngine.getVisitsFiltered().length;
		const visitsFilteredArray = this._searchEngine.getVisitsFiltered();
		
		if (nbStart === 0 && visitsFilteredLength > 0)
		{
			if (!isStream)
			{
				WebView360GlobalSettings.debug && console.debug("[VisitsRenderController] showVisits hide loader");
				DomHelper.removeAll(this._panomap.getVisitsContainer().parentElement, "div#loader");
			}
			DomHelper.removeAll(this._panomap.getVisitsContainer(), "div.wv_item");
			DomHelper.removeAll(this._panomap.getVisitsContainer(), "h2#no_data");
			this._visitsDisplayed = 0;
		}
		else if (visitsFilteredLength === 0)
		{
			if (!isStream)
			{
				WebView360GlobalSettings.debug && console.debug("[VisitsRenderController] showVisits hide loader");
				DomHelper.removeAll(this._panomap.getVisitsContainer().parentElement, "div#loader");
			}
			DomHelper.removeAll(this._panomap.getVisitsContainer(), "div.wv_item");

			this._panomap.getVisitsContainer().appendChild(this._noDataElement);

			/** only on text search with no result reinit map */
			if (this._panomap.getSearchFactory().getSearchTextElement().value.length > 0)
			{
				this._panomap.getMapController().reinitMapOptions();
			}
			this._visitsDisplayed = 0;
		}

		let limit = nbPano + nbStart;

		if (nbPano === 0)
		{
			nbPano = visitsFilteredLength;
		}

		if (this._config.limit > 0)
		{
			limit = this._config.limit - i;
		}

		while (i <= limit - 1 && i < visitsFilteredArray.length)
		{
			this._panomap.getVisitsContainer().appendChild(this.buildVisitItem(visitsFilteredArray[i]));
			i++;
		}

		this._visitsDisplayed += (i - nbStart);
		WebView360GlobalSettings.debug && console.debug(`[VisitsRenderController] showVisits => ${this._visitsDisplayed} visits displayed`);
	}

	public renderVisitsByMapBouding() : void
	{
		WebView360GlobalSettings.debug && console.debug("[VisitsRenderController] renderVisitsByMapBouding");
		this._searchEngine.filterVisitsByMap((visit: IVisitItem) =>
			(this._panomap.getMapController().getMap().getBounds().contains(visit.marker.getPosition()) &&
			!DomHelper.isUnderDrawer(this._panomap.getDrawer().getDrawerElement(), this._panomap.getMapController().getMarkerPxPosition(visit.marker.getPosition()))));
	}

	private _buildVisitItemLogic(visitItemDOM: IVisitItemDOM, visitItem: IVisitItem): void
	{
		const visit = visitItem.visit;
		const date2MonthsAgo = new Date();
		date2MonthsAgo.setMonth(date2MonthsAgo.getMonth() - 2);
	
		if (Date.parse(visit.Date_PDV) > Date.parse(date2MonthsAgo.toDateString()) && this._config.showNewAttr)
		{
			visitItemDOM.itemAttributesRightContainer.appendChild(visitItemDOM.newIcon);
		}

		if ((this._config.db === "ve" || (!this._config.db && visit.Lien_VE)) && this._config.showVEAttr)
		{
			visitItemDOM.itemAttributesRightContainer.appendChild(visitItemDOM.veIcon);
		}

		if ((this._config.db === "dr" || (!this._config.db && visit.Drone && visit.Drone_Iframe_VVP)) && this._config.showVAAttr )
		{
			visitItemDOM.itemAttributesRightContainer.appendChild(visitItemDOM.droneIcon);
		}
		
		if (visit.Favoris && this._config.showFavAttr)
		{
			visitItemDOM.itemAttributesLeftContainer.appendChild(visitItemDOM.favIcon);
		}

		if (this._config.showAct)
		{
			visitItemDOM.underCaption.appendChild(visitItemDOM.visitSubCategory);
		}

		visitItemDOM.underCaption.appendChild(document.createElement("BR"));

		if (this._config.showDep)
		{
			visitItemDOM.underCaption.appendChild(visitItemDOM.visitDepartment);
		}

		if (this._config.showCity)
		{
			if (this._config.showDep)
			{
				visitItemDOM.underCaption.appendChild(document.createTextNode(" - "));
			}
			visitItemDOM.underCaption.appendChild(visitItemDOM.visitCity);
		}

		if (this._config.showDlButton)
		{
			visitItemDOM.controlLeftButtonsContainer.appendChild(visitItemDOM.downloadControlAction);
		}

		if (this._config.showCRMButton)
		{
			visitItemDOM.controlLeftButtonsContainer.appendChild(visitItemDOM.goToClientControlAction);
		}
		
		if (this._config.showRepButton)
		{
			visitItemDOM.controlLeftButtonsContainer.appendChild(visitItemDOM.reportControlAction);
		}

		if (this._config.showGSVButton && visit.ID_Gothru)
		{
			visitItemDOM.controlRightButtonsContainer.appendChild(visitItemDOM.gsvControlAction);
		}

		if (this._config.showOverlayButton && visit.ID_Overlay)
		{
			visitItemDOM.controlRightButtonsContainer.appendChild(visitItemDOM.overlayControlAction);
		}

		visitItemDOM.controlButtonsContainer.appendChild(visitItemDOM.controlLeftButtonsContainer);
		visitItemDOM.controlButtonsContainer.appendChild(visitItemDOM.controlRightButtonsContainer);
		
		visitItemDOM.sideButtonsBar.appendChild(visitItemDOM.locateAction);
		visitItemDOM.sideButtonsBar.appendChild(visitItemDOM.seeOnGMapAction);

		visitItemDOM.itemImg.appendChild(visitItemDOM.itemAttributesLeftContainer);
		visitItemDOM.itemImg.appendChild(visitItemDOM.visitIcon);
		visitItemDOM.itemImg.appendChild(visitItemDOM.itemAttributesRightContainer);
		
		visitItemDOM.caption.appendChild(visitItemDOM.visitName);
		visitItemDOM.caption.appendChild(visitItemDOM.underCaption);

		visitItemDOM.captionContainer.appendChild(visitItemDOM.caption);
		visitItemDOM.captionContainer.appendChild(visitItemDOM.sideButtonsBar);
		
		visitItemDOM.item.appendChild(visitItemDOM.itemImg);
		visitItemDOM.item.appendChild(visitItemDOM.captionContainer);

		visitItemDOM.itemRoot.appendChild(visitItemDOM.item);
		visitItemDOM.itemRoot.appendChild(visitItemDOM.controlButtonsContainer);
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	private _buildVisitItemDOM(visitItemDOM: IVisitItemDOM, visitItem: IVisitItem): void
	{
		const visit = visitItem.visit;
		visitItemDOM.item = HTMLElementFactory.createHTMLDivElement(visit.Id,
			{
				class: "wv_item",
			}
		);

		visitItemDOM.itemRoot = HTMLElementFactory.createHTMLDivElement(visit.Id,
			{
				class: "wv_item wv_item_root",
			}
		);
		
		const onItemImgClick = this._getItemImgOnClickHandler(visit);
		
		visitItemDOM.itemImg = HTMLElementFactory.createHTMLDivElement(`wv_item_img_${visit.Id}`, { class: "wv_item_img" }, { backgroundImage: `url("${visit.Pic_URI}")` }, // TODO: handle error on image & drone image
			undefined,
			() =>
			{
				if (!this._config.db && visit.Drone && visit.Drone_Iframe_VVP)
				{
					visitItemDOM.itemImg.classList.add("visit_view_choice");
					DomHelper.removeAll(visitItemDOM.itemImg);
					visitItemDOM.itemImg.appendChild(visitItemDOM.droneChoice);
					visitItemDOM.itemImg.appendChild(visitItemDOM.indoorChoice);
				}
				else
				{
					onItemImgClick();
				}
			},
			() =>
			{
				visitItemDOM.visitIcon.style.display = "initial";
			},
			() =>
			{
				if (visitItemDOM.itemImg.contains(visitItemDOM.droneChoice))
				{
					visitItemDOM.itemImg.classList.remove("visit_view_choice");
					DomHelper.removeAll(visitItemDOM.itemImg);
					visitItemDOM.itemImg.appendChild(visitItemDOM.itemAttributesLeftContainer);
					visitItemDOM.itemImg.appendChild(visitItemDOM.visitIcon);
					visitItemDOM.itemImg.appendChild(visitItemDOM.itemAttributesRightContainer);
				}
				visitItemDOM.visitIcon.style.display = "";
			}
		);

		visitItemDOM.itemAttributesRightContainer = HTMLElementFactory.createHTMLSpanElement(null, undefined, undefined, { class: "item_attr attr_right" });
		visitItemDOM.itemAttributesLeftContainer = HTMLElementFactory.createHTMLSpanElement(null, undefined, undefined, { class: "item_attr attr_left"});
		visitItemDOM.veIcon = HTMLElementFactory.createHTMLImgElement("ve_visit", `${WebView360GlobalSettings.iconsPath}version-enrichie.png`);
		visitItemDOM.droneIcon = HTMLElementFactory.createHTMLImgElement("drone_visit", `${WebView360GlobalSettings.iconsPath}vue-aerienne.png`);
		visitItemDOM.favIcon = HTMLElementFactory.createHTMLImgElement("fav_visit", `${WebView360GlobalSettings.iconsPath}coeur.png`);
		visitItemDOM.newIcon = HTMLElementFactory.createHTMLImgElement("new_visit", `${WebView360GlobalSettings.iconsPath}nouveau.png`);
		visitItemDOM.visitIcon = HTMLElementFactory.createHTMLImgElement("visit_icon", `${WebView360GlobalSettings.iconsPath}play.png`, undefined, undefined, undefined,
			() => 
			{
				visitItemDOM.visitIcon.style.opacity = "1";
			},
			() => 
			{
				visitItemDOM.visitIcon.style.opacity = "";
			}
		);

		visitItemDOM.droneChoice = HTMLElementFactory.createHTMLSpanElement("drone_choice", undefined, undefined, { class: "visit_iframe_choice" },
			(event) =>
			{
				event.stopPropagation();
				visitItemDOM.itemImg.classList.remove("visit_view_choice");
				DomHelper.removeAll(visitItemDOM.itemImg);
				visitItemDOM.itemImg.appendChild(visitItemDOM.itemAttributesLeftContainer);
				visitItemDOM.itemImg.appendChild(visitItemDOM.visitIcon);
				visitItemDOM.itemImg.appendChild(visitItemDOM.itemAttributesRightContainer);
				this._showStandardIframe(visit.Drone_Iframe_VVP);
			},
			"DRONE"
		);

		visitItemDOM.indoorChoice = HTMLElementFactory.createHTMLSpanElement("indoor_choice", undefined, undefined, { class: "visit_iframe_choice" },
			(event) =>
			{
				event.stopPropagation();
				visitItemDOM.itemImg.classList.remove("visit_view_choice");
				DomHelper.removeAll(visitItemDOM.itemImg);
				visitItemDOM.itemImg.appendChild(visitItemDOM.itemAttributesLeftContainer);
				visitItemDOM.itemImg.appendChild(visitItemDOM.visitIcon);
				visitItemDOM.itemImg.appendChild(visitItemDOM.itemAttributesRightContainer);
				onItemImgClick();
			},
			"INTERIEUR"
		);

		visitItemDOM.captionContainer = HTMLElementFactory.createHTMLDivElement("caption_container");
		visitItemDOM.caption = HTMLElementFactory.createHTMLDivElement("caption");
		visitItemDOM.visitName = HTMLElementFactory.createHTMLHeadingElement(3, `visit_name_${visit.Id}`, visit.Nom, { class: "loc_name"});
		visitItemDOM.underCaption = HTMLElementFactory.createHTMLSpanElement("under_caption");
		visitItemDOM.visitSubCategory = HTMLElementFactory.createHTMLSpanElement("visit_sub_category", undefined, undefined, { class: "undercaption_clickable" },
			() =>
			{
				// filter based on sous cat if click enaled
				this._config.clickEnabled && this._panomap.getSearchFactory().searchByValueField([{value: visit.Categorie, field: "Categorie"}, {value: visit.Sous_cat, field: "Sous_cat"}]);
			},
			visit.Sous_cat
		);
		
		visitItemDOM.visitDepartment = HTMLElementFactory.createHTMLSpanElement("visit_department", undefined, undefined, { class: "undercaption_clickable" },
			() =>
			{
				// filter based on department if click enaled
				this._config.clickEnabled && this._panomap.getSearchFactory().searchByValueField([{value: visit.Departement, field: "Departement"}]);
			},
			visit.Departement
		);
		
		visitItemDOM.visitCity = HTMLElementFactory.createHTMLSpanElement("visit_city", undefined, undefined, { class: "undercaption_clickable" },
			() =>
			{
				// filter based on city if click enaled
				this._config.clickEnabled && this._panomap.getSearchFactory().searchByValueField([{value: visit.Departement, field: "Departement"}, {value: visit.Ville, field: "Ville"}]);
			},
			visit.Ville
		);

		visitItemDOM.sideButtonsBar = HTMLElementFactory.createHTMLDivElement("side_button_bar", { class: "flex_button_container"});
		visitItemDOM.downloadControlAction = HTMLElementFactory.createHTMLImgElementInAnchor("dl_action", `${WebView360GlobalSettings.iconsPath}Images.png`, "_blank", visit.Pic_URI, { class: "lat_bar_action_button"}, "Télécharger");
		visitItemDOM.reportControlAction = HTMLElementFactory.createHTMLImgElementInAnchor("rep_action", `${WebView360GlobalSettings.iconsPath}Form.png`, "_blank", `https://forms.zohopublic.com/webvisite360/form/Modifportfolio/formperma/0XcWJCivwIzsDnid0C8c61-HUjHSSRDAbxyKVjCIyqE?idcontact=${visit.Id}&nom=${visit.Nom}`, { class: "lat_bar_action_button"}, "Rapport");
		visitItemDOM.gsvControlAction = HTMLElementFactory.createHTMLImgElementInAnchor("gsv_action", `${WebView360GlobalSettings.iconsPath}Edit-GSV.png`, "_blank", `https://gothru.co/view_constellation.php?id=${visit.ID_Gothru}`, { class: "lat_bar_action_button"}, "GSV");
		visitItemDOM.overlayControlAction = HTMLElementFactory.createHTMLImgElementInAnchor("overlay_action", `${WebView360GlobalSettings.iconsPath}Edit-overlay.png`, "_blank", ` https://gothru.co/oeditor/${visit.ID_Overlay}`, { class: "lat_bar_action_button"}, "Overlay");
		visitItemDOM.goToClientControlAction = HTMLElementFactory.createHTMLImgElementInAnchor("go_to_cl_action", `${WebView360GlobalSettings.iconsPath}CRM.png`, "_blank", `https://crm.zoho.com/crm/org31229964/tab/Contacts/${visit.Id}`, { class: "lat_bar_action_button"}, "Contact");
		visitItemDOM.seeOnGMapAction = HTMLElementFactory.createHTMLImgElementInAnchor("see_on_gmap_action", `${WebView360GlobalSettings.iconsPath}seeongm.png`, "_blank", visit.Page_GMap, { class: "lat_bar_action_button"}, "Voir sur Google Maps");
		visitItemDOM.locateAction = HTMLElementFactory.createHTMLImgElement("loc_action", `${WebView360GlobalSettings.iconsPath}loc_map.png`, { class: "lat_bar_action_button"}, "Voir sur la carte",
			() =>
			{
				this._panomap.getMapController().centerMap(visitItem.marker);
			}
		);

		visitItemDOM.controlButtonsContainer = HTMLElementFactory.createHTMLDivElement("ctrl_buttons_container");
		visitItemDOM.controlLeftButtonsContainer = HTMLElementFactory.createHTMLDivElement("ctrl_left_buttons_container", { class: "ctrl_side_buttons_container"});
		visitItemDOM.controlRightButtonsContainer = HTMLElementFactory.createHTMLDivElement("ctrl_right_buttons_container", { class: "ctrl_side_buttons_container"});

	}

	private _getItemImgOnClickHandler(visit: IVisit): Function
	{
		if (visit.Lien_VE && this._config.db != "bu")
		{
			return () =>
			{
				this._showStandardIframe(visit.Lien_VE);
			};
		}
		else
		{
			return () =>
			{
				this._showStandardIframe(visit.iFrame);
			};
		}
	}

	public getVisistsDisplayed(): number
	{
		return this._visitsDisplayed;
	}

	public setIFrameContainer(iframeItem: HTMLElement): void
	{
		this._iframeItem = iframeItem;
	}

	private _showStandardIframe(iframeUrl: string) 
	{
		DomHelper.removeAll(this._iframeItem);
		this._iframeItem.appendChild(HTMLElementFactory.createHTMLLoaderElement());
		this._iframeItem.appendChild(HTMLElementFactory.createHTMLIFrameElement(iframeUrl, "100%", "100%", "0", {border: "0"}));
		this._iframeItem.parentElement.classList.add("showed");
		DomHelper.isOnIframe() && this._iframeItem.parentElement.requestFullscreen();
	}
}