import { IAPIController } from "apiController";
import { IVisit, Plateform } from "core";
import { WebView360GlobalSettings } from "globalSettings";
import { IMapController } from "mapController";

export interface IDataController
{
	init(apiController: IAPIController, plateform: Plateform, buildWVItem: (visit: IVisitItem) => HTMLElement, mapController: IMapController): void
	initAndGetVisits(apiController: IAPIController, plateform: Plateform, buildVisitItem: (visit: IVisitItem) => HTMLElement, mapController: IMapController, onEachVisitsResult: (data: VisitArray) => void): Promise<VisitArray>
	getCategories(): Promise<string[]>
	getSubCategories(category: string): Promise<string[]>
	getDepartments(): Promise<string[]>
	getCities(department: string): Promise<string[]>
	getVisitsPromise(): Promise<VisitArray>
	getVisits(): VisitArray
	getVisitsByStream(): VisitArray
	createVisitItem(visit: IVisit): IVisitItem
}

export interface IVisitItem
{
	visit: IVisit;
	marker: google.maps.Marker;
}


export class VisitArray extends Array<IVisitItem>
{
	public static getMarkers(visits: VisitArray): google.maps.Marker[] 
	{
		const markers = [];
		visits.forEach((searchItem) =>
		{
			markers.push(searchItem.marker);
		});
		return markers;
	}
}
export class DataController implements IDataController
{
	private _apiController: IAPIController;
	private _mapController: IMapController;
	private _buildVisitItem: (visit: IVisitItem) => HTMLElement;

	private _visitsRaw: Array<IVisit>;
	private _visits: VisitArray;
	private _visitsByStream: VisitArray;

	private _categories: Array<string>;
	private _subCategories: Object;
	private _departments: Array<string>;
	private _cities: Object;
	private _plateform: Plateform;

	public constructor()
	{
		this._subCategories = {};
	}

	public init(apiController: IAPIController, plateform: Plateform, buildVisitItem: (visit: IVisitItem) => HTMLElement, mapController: IMapController): void
	{
		WebView360GlobalSettings.debug && console.debug("[DataController] init");

		this._apiController = apiController;
		this._buildVisitItem = buildVisitItem;
		this._mapController = mapController;
		this._plateform = plateform;
	}

	public initAndGetVisits(apiController: IAPIController, plateform: Plateform, buildVisitItem: (visit: IVisitItem) => HTMLElement, mapController: IMapController, onEachVisitsResult: (data: VisitArray) => void): Promise<VisitArray>
	{
		this.init(apiController, plateform, buildVisitItem, mapController);

		return this.getVisitsPromiseStream(onEachVisitsResult);
	}
	
	public getCategories(): Promise<string[]> 
	{
		WebView360GlobalSettings.debug && console.debug("[DataController] getCategories");

		if (this._categories?.length > 0)
		{
			return Promise.resolve(this._categories);
		}
		else
		{
			return this._apiController.getCategories()
				.then((categories) =>
				{
					this._categories = categories.map(x => x[Object.keys(x)[0]]);
				})
				.then(() => Promise.resolve(this._categories));
		}
	}
	
	public getSubCategories(category: string): Promise<string[]> 
	{
		WebView360GlobalSettings.debug && console.debug("[DataController] getSubCategories");

		if (this._subCategories?.[category])
		{
			return Promise.resolve(this._subCategories[category]);
		}
		else
		{
			return this._apiController.getSubCategories(category)
				.then((subCategories) =>
				{
					this._subCategories[category] = subCategories.map(x => x[Object.keys(x)[0]]);
				})
				.then(() => Promise.resolve(this._subCategories[category]));

		}
	}
	
	public getDepartments(): Promise<string[]> 
	{
		WebView360GlobalSettings.debug && console.debug("[DataController] getDepartments");

		if (this._departments?.length > 0)
		{
			return Promise.resolve(this._departments);
		}
		else
		{
			return this._apiController.getDepartments()
				.then((departments) =>
				{
					this._departments = departments.map(x => x[Object.keys(x)[0]]);
				})
				.then(() => Promise.resolve(this._departments));

		}
	}
	
	public getCities(department: string): Promise<string[]> 
	{
		WebView360GlobalSettings.debug && console.debug("[DataController] getCities");

		if (this._cities?.[department])
		{
			return Promise.resolve(this._cities[department]);
		}
		else
		{
			return this._apiController.getCities(department)
				.then((cities) =>
				{
					this._cities[department] = cities.map(x => x[Object.keys(x)[0]]);
				})
				.then(() => Promise.resolve(this._cities[department]));

		}
	}
	
	public getVisitsPromise(): Promise<VisitArray> 
	{
		WebView360GlobalSettings.debug && console.debug("[DataController] getVisitsPromise");

		if (this._visits?.length > 0)
		{
			return Promise.resolve(this._visits);
		}
		else
		{
			return this._apiController.getVisits()
				.then((visits) => Promise.resolve(visits.filter((x: IVisit) => !this._mapController || ((x.GPS_Lat && x.GPS_Lng) && this._mapController.isInInitialMapBounds({lat: x.GPS_Lat, lng: x.GPS_Lng})))))
				.then((visits) =>
				{
					this._visitsRaw = visits as IVisit[];
					this._visits = visits.map(x => (this.createVisitItem(x as IVisit) as IVisitItem)) as VisitArray;
				})
				.then(() => Promise.resolve(this._visits));
		}
	}

	public createVisitItem(visit: IVisit): IVisitItem
	{
		return {visit: visit, marker: this._createMarker(visit)};
	}

	public getVisitsPromiseStream(onEachResult: (data: Object[]) => void): Promise<VisitArray> 
	{
		WebView360GlobalSettings.debug && console.debug("[DataController] getVisitsPromise");

		if (this._visits?.length > 0)
		{
			return Promise.resolve(this._visits);
		}
		else
		{
			return this._apiController.getVisitsStream((data: Object[]) => 
			{
				new Promise((resolve) => 
				{
					if (!this._visitsByStream)
					{
						this._visitsByStream = new VisitArray();
					}
					const visits = data.filter((x: IVisit) => !this._mapController || ((x.GPS_Lat && x.GPS_Lng) && this._mapController.isInInitialMapBounds({lat: x.GPS_Lat, lng: x.GPS_Lng})))
						.map(x => ({visit: x, marker: this._createMarker(x as IVisit)} as IVisitItem));
					this._visitsByStream = this._visitsByStream.concat(visits);
					onEachResult(visits as VisitArray);
					resolve(null);
				});
			})
				.then((visits) => Promise.resolve(visits.filter((x: IVisit) => !this._mapController || ((x.GPS_Lat && x.GPS_Lng) && this._mapController.isInInitialMapBounds({lat: x.GPS_Lat, lng: x.GPS_Lng})))))
				.then((visits) =>
				{
					this._visitsRaw = visits as IVisit[];
					this._visits = visits.map(x => ({visit: x, marker: this._createMarker(x as IVisit)} as IVisitItem)) as VisitArray;
				})
				.then(() => Promise.resolve(this._visits));
		}
	}
	
	public getVisits(): VisitArray 
	{
		WebView360GlobalSettings.debug && console.debug("[DataController] getVisits");

		return this._visits;
	}

	public getVisitsByStream(): VisitArray 
	{
		WebView360GlobalSettings.debug && console.debug("[DataController] getVisitsByStream");

		return this._visitsByStream;
	}

	private _registerMarkerOverListener(marker: google.maps.Marker, visit: IVisit)
	{
		marker.addListener("mouseover", () =>
		{
			this._mapController.getInfoWindow().setContent(this._buildVisitItem({visit: visit, marker: marker} as IVisitItem));
			this._mapController.getInfoWindow().open(this._mapController.getMap(), marker);
		});

		marker.addListener("mouseout", () =>
		{
			this._mapController.getInfoWindow().close();
		});
	}

	private _createMarker(visit: IVisit): google.maps.Marker
	{
		const marker = this._mapController?.createMarker(visit, this._plateform);

		marker && marker.addListener("click", () =>
		{
			// ON OVER MODE ((this._mapController.getInfoWindow().getContent() as HTMLElement).querySelector(".wv_item_img") as HTMLElement).click();
			
			this._mapController.getInfoWindow().setContent(this._buildVisitItem({visit: visit, marker: marker} as IVisitItem));
			this._mapController.getInfoWindow().open(this._mapController.getMap(), marker);
			this._mapController.centerMap(marker, false);
			google.maps.event.clearListeners(marker, "mouseover");
			google.maps.event.clearListeners(marker, "mouseout");
			this._mapController.getInfoWindow().addListener("closeclick", () => 
			{
				this._registerMarkerOverListener(marker, visit);
			});
			this._mapController.getInfoWindow().addListener("content_changed", () => 
			{
				this._registerMarkerOverListener(marker, visit);
			});
		});

		//marker && this._registerMarkerOverListener(marker, visit);

		return marker;
	}

}